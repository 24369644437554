import './App.css';
import Home from './components/native/home.jsx'
import Phone_menu from './components/native/phone_menu.jsx'

import Menu from './components/native/menu.jsx'
import Supply_menu from './components/inventory/supply_menu.jsx'
import Orders_menu from  './components/orders/orders_menu.jsx'
import Accounting_menu from './components/accounting/accounting_menu.jsx'
import Accounts_menu from './components/accounts/accounts_menu.jsx'
import Device_menu from './components/devices/devices_menu.jsx'
import Budget_menu from './components/budget/budget.jsx'
import HR_menu from './components/human_resource/hr.jsx'
import CRM_menu from './components/customer_relation/crm.jsx'

import ACBenefits from './components/function-benefits-instructions/benefits/accounting.js'
import BUBenefits from './components/function-benefits-instructions/benefits/budget.js'
import CUBenefits from './components/function-benefits-instructions/benefits/customer.js'
import EQBenefits from './components/function-benefits-instructions/benefits/equipment.js'
import HRBenefits from './components/function-benefits-instructions/benefits/hr.js'
import ORBenefits from './components/function-benefits-instructions/benefits/order.js'
import SUBenefits from './components/function-benefits-instructions/benefits/supply.js'

import Features from './components/function-benefits-instructions/features.js'
import Updates from './components/function-benefits-instructions/updates.js'
import Instructions from './components/function-benefits-instructions/instructions.js'
import Feedback_form from './components/function-benefits-instructions/feedback.js'
import Report_bugs from './components/function-benefits-instructions/bugs.js'
import {
  Routes,
  Route
} from "react-router-dom";

function App() {
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  
  if (isMobile) {
  return (
    <div className="App">
      <div className="sorry">
        <center>We apologize, but due to privacy converns we will not be optimizing Cloudease for mobile devices.</center>
        <br />
        <center>Please watch for updates as we are preparing to create a mobile application for Android and Apple devices.</center>
      </div>
    </div>
    );
  } else {
    return (
      <div className="App">

        <Routes>
                <Route element={<Home/>} path="/"/>
                <Route element={<Menu/>} path="/Menu"/>
                <Route element={<Supply_menu/>} path="/Supply"/>
                <Route element={<Orders_menu/>} path="/Orders"/>
                <Route element={<Accounting_menu/>} path="/Accounting"/>
                <Route element={<Accounts_menu/>} path="/Account"/>
                <Route element={<Device_menu/>} path="/Devices"/>
                <Route element={<Budget_menu/>} path="/Budget"/>
                <Route element={<HR_menu/>} path="/Human-resources"/>
                <Route element={<CRM_menu/>} path="/Customer-relations"/>


                <Route element={<ACBenefits/>} path="/Accounting-benefits"/>
                <Route element={<BUBenefits/>} path="/Budgeting-benefits"/>
                <Route element={<CUBenefits/>} path="/Customer-relations-benefits"/>
                <Route element={<EQBenefits/>} path="/Equipment-management-benefits"/>
                <Route element={<HRBenefits/>} path="/Human-resources-benefits"/>
                <Route element={<ORBenefits/>} path="/Order-management-beneifts"/>
                <Route element={<SUBenefits/>} path="/Supply-management-benefits"/>

                <Route element={<Features/>} path="/Features"/>
                <Route element={<Updates/>} path="/Updates"/>
                <Route element={<Instructions/>} path="/Instructions"/>
                <Route element={<Feedback_form/>} path="/Feedback-form"/>
                <Route element={<Report_bugs/>} path="/Report-bugs-form"/>

          </Routes>
      </div>
    );
  }
  }
  
export default App;
