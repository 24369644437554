import React, {useState} from 'react'
import { useNavigate } from "react-router-dom";
import Recruit from '../recruitment/recruitment.js'
import Users from '../users/users.js'
import './hr.css'
const HR_menu = () =>{
    let navigate = useNavigate();
    const home = <button className="button-66" onClick={(e)=>{e.preventDefault();navigate('/Menu')}}>Home</button>
    const [choice, setChoice] = useState("")
    const back = <button onClick={()=>{setChoice("")}} className="button-37">Back</button>
    const ads = process.env.REACT_APP_BUILD
    switch(choice){
        case 0:
            return null
            break
        case 1 :
            return(
                <div>
                    {back}
                    <h1>Recruitment</h1>
                    <Recruit/> 
                    <iframe className="background_2" width="100%" height="1080" src="https://api.maptiler.com/maps/33ac29e1-786b-4779-b142-2ca40194b55a/?key=pqD8WLIbS6twjarh6dJO#3.3/35.91279/-96.30308"></iframe>
                    
                </div>
            )
        case 2 :
            return(
                <div>
                    {back}
                    <h1>Users</h1>
                    <Users/> 
                    <iframe className="background_2" width="100%" height="1080" src="https://api.maptiler.com/maps/33ac29e1-786b-4779-b142-2ca40194b55a/?key=pqD8WLIbS6twjarh6dJO#3.3/35.91279/-96.30308"></iframe>
                    
                </div>
            )
        default:
            return(
                <div className="whole_container">
                    {home}
                    <div className="hr_button_container">
                        <button className="hr_b" onClick={()=>{setChoice(1)}}>Recruitment</button>
                        <button className="hr_b" onClick={()=>{setChoice(2)}}>Users</button>
                    </div>
                    <div className="description_container">
                        <h3>There are several benefits of using an application to track recruitments and employees/users. The top three benefits are:</h3>
                        <h2>1. Efficiency:</h2> Using an application for recruitment and employee/user tracking streamlines the entire process, making it more efficient. It allows HR departments to automate various tasks such as job posting, resume screening, interview scheduling, and onboarding. This saves time and effort, enabling HR professionals to focus on more strategic activities.
                        <h2>2. Accuracy:</h2> An application provides a centralized database for all recruitment and employee/user information, ensuring accuracy and consistency. It eliminates the need for manual record-keeping, reducing the chances of errors or data duplication. This improves data integrity and enables HR teams to make informed decisions based on reliable information.
                        <h2>3. Analytics and Reporting:</h2> Tracking applications often come with built-in analytics and reporting features. These tools provide valuable insights into recruitment metrics, employee/user performance, and other relevant data. HR professionals can generate reports on key performance indicators (KPIs), turnover rates, training needs, and more. This data-driven approach helps in identifying trends, making data-backed decisions, and improving overall recruitment and employee/user management strategies.
                    </div>
                    {
                        ads?
                        <div><script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3199046355212507"
                        crossorigin="anonymous"></script></div>
                        : <div></div>
                    }
                </div>
            )
    }
}
export default HR_menu