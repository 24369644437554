import React, {useState} from 'react'
import { useNavigate } from "react-router-dom";
import Budget from './budget.js'
import Create_budget from './create_budget.js'
import './budget.css'
const Budget_menu = () =>{
    let navigate = useNavigate();
    const home = <button className="button-66" onClick={(e)=>{e.preventDefault();navigate('/Menu')}}>Home</button>
    const [choice, setChoice] = useState("")
    const back = <button className="button-37" onClick={()=>{setChoice("")}}>Budget Menu</button>
    const ads = process.env.REACT_APP_BUILD
    switch(choice){
        case 0:
            return(
                <div>
                    {back}
                    <Budget/>
                    <iframe className="background_2" width="100%" height="1080" src="https://api.maptiler.com/maps/33ac29e1-786b-4779-b142-2ca40194b55a/?key=pqD8WLIbS6twjarh6dJO#3.3/35.91279/-96.30308"></iframe>
                </div>
            )
        case 1:
            return(
                <div>
                    {back}
                    <Create_budget/>
                    <iframe className="background_2" width="100%" height="1080" src="https://api.maptiler.com/maps/33ac29e1-786b-4779-b142-2ca40194b55a/?key=pqD8WLIbS6twjarh6dJO#3.3/35.91279/-96.30308"></iframe>
                </div>
            )
        default:
            return(
                <div className="whole_container">
                    {home}
                    <div className="budget_button_container">
                        <button className="budget_b" onClick={()=>{setChoice(0)}}>Manage Budgets</button>
                        <button className="budget_b" onClick={()=>{setChoice(1)}}>Create Budget</button>
                    </div>
                    <div className="description_container">
                    <h3>Creating a budget offers several benefits that can greatly improve one's financial well-being. The top three benefits are:</h3>
                    <h2> 1. Financial Control:</h2> A budget provides a clear overview of income and expenses, allowing individuals to track their spending and identify areas where they can cut back or save. By having a detailed understanding of their financial situation, individuals can make informed decisions and have better control over their money.
                    <h2>2. Goal Setting:</h2> Budgeting helps individuals set and achieve financial goals. Whether it's saving for a down payment on a house, paying off debt, or planning for retirement, a budget allows individuals to allocate funds towards specific objectives. By regularly reviewing and adjusting the budget, individuals can stay on track and make progress towards their goals.
                    <h2>3. Reduced Stress:</h2> A budget helps reduce financial stress by providing a sense of security and stability. With a budget in place, individuals can avoid overspending, manage debt effectively, and build an emergency fund. This financial security leads to peace of mind and reduces anxiety about money-related issues.
                    </div>
                    <div className="description_container_2">
                        <h2>Creating a budget is an essential step in managing personal or business finances.</h2> It helps individuals and organizations track their income, expenses, and savings to ensure financial stability and achieve financial goals. Here is a comprehensive guide on how to create a budget:

                        <h2>1. Assess your current financial situation:</h2> Before creating a budget, it is crucial to have a clear understanding of your current financial situation. Start by gathering all relevant financial documents, such as bank statements, pay stubs, bills, and receipts. Calculate your total income from all sources and determine your average monthly income.
                        <h2> 2. Track your expenses:</h2> To create an accurate budget, you need to track your expenses over a specific period, such as a month. Categorize your expenses into fixed (e.g., rent/mortgage, utilities) and variable (e.g., groceries, entertainment). Use tools like spreadsheets or budgeting apps to record your expenses and ensure accuracy.
                        <h2>3. Identify essential and non-essential expenses:</h2> Review your tracked expenses and identify which ones are essential for your basic needs (e.g., housing, food, transportation) and which ones are non-essential (e.g., dining out, subscriptions). This step helps prioritize spending and identify areas where you can potentially cut back.
                        <h2>4. Set financial goals:</h2> Determine your short-term and long-term financial goals. Short-term goals may include paying off debt or saving for a vacation, while long-term goals could be retirement planning or buying a house. Setting clear goals will help you allocate funds accordingly in your budget.
                        <h2> 5. Create income and expense categories:</h2> Divide your income and expenses into different categories to organize your budget effectively. Common income categories include salary/wages, investments, rental income, etc. Expense categories may include housing, transportation, groceries, debt payments, entertainment, etc.
                        <h2>6. Estimate income:</h2> Based on your average monthly income from step 1, estimate your income for each category. If you have a fixed salary, this step is relatively straightforward. However, if your income fluctuates, it is advisable to estimate conservatively to avoid overspending.
                        <h2>7. Estimate expenses:</h2> Using the data from step 2, estimate your expenses for each category. For fixed expenses, such as rent or mortgage payments, use the actual amounts. For variable expenses, calculate an average based on your tracking data.
                        <h2>8. Allocate funds:</h2> Allocate funds to each category based on your financial goals and priorities. Start with essential expenses and savings goals, then distribute the remaining income among non-essential categories. Ensure that your total allocated funds do not exceed your estimated income.
                        <h2>9. Monitor and adjust:</h2> Creating a budget is not a one-time task; it requires regular monitoring and adjustments. Track your actual income and expenses each month and compare them to your budgeted amounts. Identify any discrepancies and make necessary adjustments to stay on track.
                        <h2>10. Review and refine:</h2> Periodically review your budget to ensure it aligns with your changing financial situation and goals. As circumstances change, such as a salary increase or new financial obligations, adjust your budget accordingly to maintain its effectiveness.
                        <h3>By following these steps,</h3> you can create a comprehensive budget that helps you manage your finances effectively and work towards achieving your financial goals.
                        
                    </div>
                    {
                        ads?
                        <div><script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3199046355212507"
                        crossorigin="anonymous"></script></div>
                        : <div></div>
                    }
                </div>
            )
    }
}
export default Budget_menu