import React, {useState} from 'react'
import { useNavigate } from "react-router-dom";
import './supply.css'
import Supply from './supply.js'
import SupplyOut from './supply_out.js'
import SupplyIn from './supply_in.js'

const Supply_menu = (props) =>{

    let navigate = useNavigate();
    const home = <button className="button-66" onClick={(e)=>{e.preventDefault();navigate('/Menu')}}>Home</button>
    const [supplyChoice, setSupplyChoice] = useState("supply_menu")
    const ads = process.env.REACT_APP_BUILD
    const back = <button className="button-37 b-37" onClick={()=>{setSupplyChoice("supply_menu")}}>Supply Menu</button>
    switch(supplyChoice){
        case 0:
            return(
                <div>
                    {back}
                    <SupplyIn/>
                    <iframe className="background_2" width="100%" height="1080" src="https://api.maptiler.com/maps/33ac29e1-786b-4779-b142-2ca40194b55a/?key=pqD8WLIbS6twjarh6dJO#3.3/35.91279/-96.30308"></iframe>
                    
                </div>
            )
        case 1:
            return(
                <div>
                    {back}
                    <SupplyOut/>
                    <iframe className="background_2" width="100%" height="1080" src="https://api.maptiler.com/maps/33ac29e1-786b-4779-b142-2ca40194b55a/?key=pqD8WLIbS6twjarh6dJO#3.3/35.91279/-96.30308"></iframe>
                    
                </div>
            )
        case 2:
            return(
                <div>
                    {back}
                    <Supply/>
                    <iframe className="background_2" width="100%" height="1080" src="https://api.maptiler.com/maps/33ac29e1-786b-4779-b142-2ca40194b55a/?key=pqD8WLIbS6twjarh6dJO#3.3/35.91279/-96.30308"></iframe>
                    
                </div>
            )

        default:
            return(
                <div className="whole_container">
                    {home}
                    <div className="supply_button_container">
                        <button className="supply_b" onClick={()=>{setSupplyChoice(2)}}>Manage Supply</button>
                   
                    </div>

                    <div className="description_container">
                        <h3>
                    Using an application to manage and track supply inventory offers numerous benefits, including the following top 3:</h3>
                        <h2>1. Efficiency:</h2> An inventory management application streamlines the process of tracking and managing supplies, reducing manual errors and saving time. It allows for real-time updates, automated notifications for low stock levels, and easy reordering.

                        <h2>2. Accuracy:</h2> By using an application, businesses can ensure accurate inventory counts and avoid overstocking or stockouts. The system can provide detailed information on item quantities, locations, and expiration dates, enabling better decision-making.

                        <h2>3. Cost savings:</h2> Effective inventory management helps minimize carrying costs by preventing excess stock and reducing the risk of obsolescence. By optimizing inventory levels, businesses can also avoid rush orders and take advantage of bulk purchasing discounts.

                    </div>
                    {
                        ads?
                        <div><script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3199046355212507"
                        crossorigin="anonymous"></script></div>
                        : <div></div>
                    }
                </div>
            )
    }
}
export default Supply_menu