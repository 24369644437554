import {Link } from "react-router-dom";
import Nav from '../native/nav_bar.jsx'
const Features = () =>{
    return(
        <div>
            <Nav/>
            <div className="description_container">
               <center><h1>Features</h1></center>
               <h2>Budgeting</h2>
               <p>Budgeting is a financial management tool that helps individuals, businesses, and organizations plan and control their income and expenses. It involves the process of creating a detailed plan for allocating financial resources over a specific period, typically a month, quarter, or year. Budgeting plays a crucial role in managing finances effectively and achieving financial goals. It provides a roadmap for making informed financial decisions, tracking progress, and ensuring financial stability.</p>
               <h2>Customer Relations</h2>
               <p>Customer relations refer to the interactions and relationships between a company or organization and its customers. It encompasses all the activities and strategies implemented by businesses to build and maintain positive relationships with their customers. Effective customer relations are crucial for the success and growth of any business, as they directly impact customer satisfaction, loyalty, and ultimately, the company's bottom line.</p>
               <h2>Equipment Management</h2>
               <p>Equipment management refers to the process of effectively and efficiently managing various types of equipment within an organization. It involves the planning, acquisition, utilization, maintenance, and disposal of equipment to ensure optimal performance, productivity, and cost-effectiveness. Equipment management plays a crucial role in industries such as construction, manufacturing, healthcare, transportation, and many others where equipment is essential for operations.</p>
               <h2>Human Resources</h2>
               <p>Human Resource Management (HRM) encompasses a wide range of functions and activities within an organization that are focused on managing the human capital. It involves the recruitment, selection, training, development, compensation, and retention of employees. HRM plays a crucial role in aligning the workforce with the strategic goals of the organization and ensuring that employees are motivated, engaged, and productive.</p>
               <h2>Order Management</h2>
               <p>Order management refers to the process of efficiently and effectively managing all aspects of an order, from its initiation to its fulfillment. It involves various activities such as order processing, inventory management, shipping, and customer service. The features of order management systems are designed to streamline and automate these processes, ensuring accuracy, timeliness, and customer satisfaction.</p>
               <h2>Supply Management</h2>
               <p>Supply management, also known as procurement or purchasing management, is a critical function within organizations that focuses on acquiring goods and services needed to support operations. It involves the strategic planning, sourcing, negotiation, and management of suppliers to ensure the availability of quality products or services at the right time and cost. Supply management plays a vital role in optimizing supply chains, reducing costs, mitigating risks, and enhancing overall organizational performance.</p>
            </div>
            <center>
            <Link className="button-32" to={'/Menu'}>Sample CloudEase</Link></center>
        </div>
    )
}
export default Features