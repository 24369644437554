import Nav from '../native/nav_bar.jsx'
const Report_bugs = () =>{
    return(
        <div>
            <Nav/>
            <div className="description_container">
               <center><h1>Report Bugs and Vulnerabilities</h1>
                <h2>Report bugs @ info@cloudease.app</h2>  
                </center></div>
        </div>
    )
}
export default Report_bugs