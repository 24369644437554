import React, {useState, useEffect} from 'react'
import axios from 'axios'
import create from "../sf/req/ce.js"
import deleteC from "../sf/req/de.js"
import edit from "../sf/req/dt.js"
import sortThis from "../sf/st.js"
import createGraph from '../sf/cg.js'
const link = process.env.REACT_APP_S
const VariableE= () =>{
    const [data, setData] = useState([["Due Date", ""], ["Recurring", ""], ["Comments", ""], ["Payment Amount", 0], ["Last Payment", ""], ["Date Started", ""], ["Date End", ""], ["City", 0], ["State", 0], ["Federal", 0]])
    const [retrieve, setRetrieved] = useState([])
    const selection =["Due Date","Recurring","Comments","Payment Amount","Last Payment","Date Started","Date End","City","State","Federal"]
    const [graph, setGraph] = useState("")
    const [graph2, setGraph2] = useState("")
    const [graph3, setGraph3] = useState("")
    const [graph4, setGraph4] = useState("")
    const [total,setTotals] = useState("")
    const [errors, setErrors] = useState("")
    const ads = process.env.REACT_APP_BUILD
    const setDataa = (e,t) =>{
        e.preventDefault()
        if(t[0].length > 25 || t[1].length > 25){
            setErrors(`${t[0]} cannot be greater then 25 Characters.`)
            return null
        }
        const copy = [...data]
        switch(t[0]){
            case selection[0]:
                copy[0] = t
            break;
            case selection[1]:
                copy[1] = t
            break;
            case selection[2]:
                copy[2] = t
            break;
            case selection[3]:
                if(!/^[0-9]+$/.test(t[1]) && t[1] !== ""){
                    setErrors(`${t[0]} must be a number`)
                    return null
                }
                setErrors("")
                copy[3] = t
            break;
            case selection[4]:
                copy[4] = t
            break;
            case selection[5]:
                copy[5] = t
            break;
            case selection[6]:
                copy[6] = t
            break;
            case selection[7]:
                if(!/^[0-9]+$/.test(t[1]) && t[1] !== ""){
                    setErrors(`${t[0]} must be a number`)
                    return null
                }
                setErrors("")
                copy[7] = t
            break;
            case selection[8]:
                if(!/^[0-9]+$/.test(t[1]) && t[1] !== ""){
                    setErrors(`${t[0]} must be a number`)
                    return null
                }
                setErrors("")
                copy[8] = t
            break;
            case selection[9]:
                if(!/^[0-9]+$/.test(t[1]) && t[1] !== ""){
                    setErrors(`${t[0]} must be a number`)
                    return null
                }
                setErrors("")
                copy[9] = t
            break;
            default:
                return false;
        }
        setData([...copy])
    }
    useEffect(() => {

        getData(null,0,10)

    },[])
    const clear = (request) =>{
        setErrors(request)
        setData([["Due Date", ""], ["Recurring", ""], ["Comments", ""], ["Payment Amount", 0], ["Last Payment", ""], ["Date Started", ""], ["Date End", ""], ["City", 0], ["State", 0], ["Federal", 0]])
    }
    const [flip, setFlip] = useState(0)
    const sortInfo = async(e, choice) =>{
      e.preventDefault()
      if(choice === 1){
        //Due_date
        const copy = retrieve
        const sorted = await sortThis(copy, "due_date", `3${flip}`)
        setRetrieved(sorted)
      }
      if(choice === 2){
        //Recurring
        const copy = retrieve
        const sorted = await sortThis(copy, "recurring", `1${flip}`)
        setRetrieved(sorted)
      }
      if(choice === 3){
        //Payment amount
        const copy = retrieve
        const sorted = await sortThis(copy, "payment_amount", `1${flip}`)
        setRetrieved(sorted)
      }
      if(choice === 4){
        //Last payment
        const copy = retrieve
        const sorted = await sortThis(copy, "last_payment_date", `3${flip}`)
        setRetrieved(sorted)
      }
      if(flip === 0){
        setFlip(1)
      }
      if(flip === 1){
        setFlip(0)
      }
    }
    const getData = async (content, choice, request) =>{
        try{
            const getAllLinks = [process.env.REACT_APP_VARIABLE_GL1,process.env.REACT_APP_VARIABLE_GL2,process.env.REACT_APP_VARIABLE_GL3,process.env.REACT_APP_VARIABLE_GL4,process.env.REACT_APP_VARIABLE_GL5,process.env.REACT_APP_VARIABLE_GL6,process.env.REACT_APP_VARIABLE_GL7,process.env.REACT_APP_VARIABLE_GL8,process.env.REACT_APP_VARIABLE_GL9,process.env.REACT_APP_VARIABLE_GL10,process.env.REACT_APP_VARIABLE_GL11,process.env.REACT_APP_VARIABLE_GL12,process.env.REACT_APP_VARIABLE_GL13,process.env.REACT_APP_VARIABLE_GL14]
            const getOneLinks = [process.env.REACT_APP_VARIABLE_GO1,process.env.REACT_APP_VARIABLE_GO2,process.env.REACT_APP_VARIABLE_GO3,process.env.REACT_APP_VARIABLE_GO4,process.env.REACT_APP_VARIABLE_GO5,process.env.REACT_APP_VARIABLE_GO6,process.env.REACT_APP_VARIABLE_GO7,process.env.REACT_APP_VARIABLE_GO8,process.env.REACT_APP_VARIABLE_GO9,process.env.REACT_APP_VARIABLE_GO10,process.env.REACT_APP_VARIABLE_GO11,process.env.REACT_APP_VARIABLE_GO12,process.env.REACT_APP_VARIABLE_GO13,process.env.REACT_APP_VARIABLE_GO14]
            const uc = [sessionStorage.getItem(process.env.REACT_APP_VARIABLE_B),sessionStorage.getItem(process.env.REACT_APP_VARIABLE_C)]
            switch(choice){
                case 0:
                    const res = await axios.post(`${link}${getAllLinks[request]}`,
                    {
                        k : process.env.REACT_APP_K,
                        'kk': uc[0],
                        'kk1': uc[1]
                    })  
                    if(!res.data.request){
                        setErrors("Please Login or Create an Account!")
                        return null
                    }
                    setRetrieved(res.data.item)
                    setGraph(createGraph(res.data.item, "recurring", "payment_amount"))
                    setGraph2(createGraph(res.data.item, "recurring", "city"))
                    setGraph3(createGraph(res.data.item, "recurring", "state"))
                    setGraph4(createGraph(res.data.item, "recurring", "federal"))
                    setTotals(totals(res.data.item))
                    setErrors("")
                    return null
                case 1: 
                    const res2 = await axios.post(`${link}${getOneLinks[request]}`,
                    {
                        k : process.env.REACT_APP_K,
                        content,
                        'kk': uc[0],
                        'kk1': uc[1]
                    })  
                    if(!res2.data.request){
                        setErrors("Please Login or Create an Account!")
                        return null
                    }
                    setData([["Due Date", res2.data.item.item.due_date], ["Recurring", res2.data.item.item.recurring], ["Comments", res2.data.item.item.reason], ["Payment Amount", res2.data.item.item.payment_amount], ["Last Payment", res2.data.item.item.last_payment_date], ["Date Started", res2.data.item.item.date_started], ["Date End", res2.data.item.item.date_end], ["City", res2.data.item.item.city], ["State", res2.data.item.item.state], ["Federal", res2.data.item.item.federal],["_id", res2.data.item._id]])
                    setErrors("")
                    return null
                default:
                    return [false, false]  
            }
        }catch{
            return [false, "Something went wrong"]
        } 
    }
    const processData = async(e, content, choice) =>{
        e.preventDefault()
        const uc = [sessionStorage.getItem(process.env.REACT_APP_VARIABLE_B),sessionStorage.getItem(process.env.REACT_APP_VARIABLE_C)]
        switch (choice){
            case 0:
                const res0 = await create(uc, content, 8)
                if(res0){
                    setErrors("Created")
                    clear("Created")
                    getData(null, 0,10)
                    return null
                }else{
                    setErrors("Error creating")
                    return null
                }
                break;
            case 1:
                const res1 = await deleteC(uc, content, 8)
                if(res1){
                    setErrors("Removed")
                    clear("Removed")
                    getData(null, 0, 10)
                    return null
                }else{
                    setErrors("Error removing")
                    return null
                }
                break;
            case 2:
                const res2 = await edit(uc, content, 11)
                if(res2){
                    setErrors("Updated")
                    clear("Updated")
                    getData(null, 0,10)
                    return null
                }else{
                    setErrors("Error updating")
                    return null
                }
                break;
            default: 
                return false
        }
    }
    const totals = (retrieve) =>{
        var total1 = 0
        var total2 = 0
        var total3 = 0
        var total4 = 0
            for(let i =0; i < retrieve.length; i++){
                total1 = total1 + parseInt(retrieve[i].item.payment_amount)
                total2 = total2 + parseInt(retrieve[i].item.city)
                total3 = total3 + parseInt(retrieve[i].item.state)
                total4 = total4 + parseInt(retrieve[i].item.federal)
            }
            return [total1,total2,total3,total4]
    }
    const getSelect = (info) =>{
        switch(info){
            case "0":
                return "Weekly"
            case "1":
                return "Bi-weekly"
            case "2":
                return "Monthly"
            case "3":
                return "Bi-monthly"
            case "4":
                return "Quarterly"
            case "5":
                return "Semi-annual"
            case "6":
                return "Annual"
        }
    }
    const c = sessionStorage.getItem("key")
    return(
        <div className="accounting_container">
            <div className="accounting_list">
                <div className="item_container">
                        <div className="item_span" onClick={(e)=>{sortInfo(e,1)}}>Due Date</div>
                        <div className="item_span" onClick={(e)=>{sortInfo(e,2)}}>Recurring</div>
                        <div className="item_span" onClick={(e)=>{sortInfo(e,3)}}>Pay Amount</div>
                        <div className="item_span" onClick={(e)=>{sortInfo(e,4)}}>Last Payment</div>
                </div>
                {
                    retrieve.map((item, id)=>{
                        return (
                            <div key={id} className="item_container">
                                <div className="item_span">{item.item.due_date} </div>
                                <div className="item_span">{getSelect(item.item.recurring)}</div>
                                <div className="item_span">${item.item.payment_amount}</div>
                                <div className="item_span">{item.item.last_payment_date}</div>
                            
                                <button className="button-2" onClick={(e)=>{getData(item._id, 1, 8)}}>Edit</button>
                                <button className="button-2" onClick={(e)=>{processData(e, item._id, 1)}}>Remove</button>
                            </div>
                        )
                    })
                }
            </div>
            <div className="accounting_form">
                <div className="accounting_uin">
                    <div className="accounting_label">
                        {data[0][0]} 
                    </div>
                    <input type="date" className="accounting_input" onChange={(e)=>{setDataa(e,[selection[0] , e.target.value])}} value={data[0][1]}/>
                </div>     
                <div className="accounting_uin">
                    <div className="accounting_label">
                        {data[1][0]} 
                    </div>
                    <select name="" id="" onChange={(e)=>{setDataa(e,[selection[1] , e.target.value])}} value={data[1][1]}>
                        <option value="0">Weekly</option>
                        <option value="1">Bi-weekly</option>
                        <option value="2">Monthly</option>
                        <option value="3">Bi-monthly</option>
                        <option value="4">Quarterly</option>
                        <option value="5">Semi-annual</option>
                        <option value="6">Annual</option>
                    </select>
                </div>   
                <div className="accounting_uin">
                    <div className="accounting_label">
                        {data[2][0]} 
                    </div>
                    <input type="text" className="accounting_input" onChange={(e)=>{setDataa(e,[selection[2] , e.target.value])}} value={data[2][1]}/>
                </div>   
                <div className="accounting_uin">
                    <div className="accounting_label">
                        {data[3][0]} 
                    </div>
                    <input type="text" className="accounting_input" onChange={(e)=>{setDataa(e,[selection[3] , e.target.value])}} value={data[3][1]}/>
                </div>   
                <div className="accounting_uin">
                    <div className="accounting_label">
                        {data[4][0]} 
                    </div>
                    <input type="date" className="accounting_input" onChange={(e)=>{setDataa(e,[selection[4] , e.target.value])}} value={data[4][1]}/>
                </div>   
                <div className="accounting_uin">
                    <div className="accounting_label">
                        {data[5][0]} 
                    </div>
                    <input type="date" className="accounting_input" onChange={(e)=>{setDataa(e,[selection[5] , e.target.value])}} value={data[5][1]}/>
                </div>   
                <div className="accounting_uin">
                    <div className="accounting_label">
                        {data[6][0]} 
                    </div>
                    <input type="date" className="accounting_input" onChange={(e)=>{setDataa(e,[selection[6] , e.target.value])}} value={data[6][1]}/>
                </div>   
                <div className="accounting_uin">
                    <div className="accounting_label">
                        {data[7][0]} 
                    </div>
                    <input type="text" className="accounting_input" onChange={(e)=>{setDataa(e,[selection[7] , e.target.value])}} value={data[7][1]}/>
                </div>   
                <div className="accounting_uin">
                    <div className="accounting_label">
                        {data[8][0]}
                    </div>
                    <input type="text" className="accounting_input" onChange={(e)=>{setDataa(e,[selection[8] , e.target.value])}} value={data[8][1]}/>
                </div>   
                <div className="accounting_uin">
                    <div className="accounting_label">
                        {data[9][0]} 
                    </div>
                    <input type="text" className="accounting_input" onChange={(e)=>{setDataa(e,[selection[9] , e.target.value])}} value={data[9][1]}/>
                </div>   
               {
                c?
                <div> 
                    <button className="button-7" onClick={(e)=>{processData(e,data, 0)}}>Create</button>   
                    <button className="button-7" onClick={(e)=>{clear("")}}>Clear</button>  
                    <button className="button-7" onClick={(e)=>{processData(e,data, 2)}}>Update</button> 
                </div>
                : <div></div>
               }
                <div>
                   <div className="error">
                        {errors}
                   </div>
                </div>
            </div>
            <div className="accounting_totals">
                <div>Total Payment : ${total[0]}</div>
                <div>Recurring City Tax : ${total[1]}</div>
                <div>Recurring State Tax : ${total[2]}</div>
                <div>Recurring Federal Tax : ${total[3]}</div>
            </div>
            <div className="accounting_graphs">
                <div className="graph_title">
                    Recurring/Payment Amount
                <div className="pie" style={{background: `conic-gradient(${graph})`}}></div>
                </div>
                <div className="graph_title">
                    Recurring/City
                <div className="pie" style={{background: `conic-gradient(${graph2})`}}></div>
                </div>
                <div className="graph_title">
                Recurring/State
                <div className="pie" style={{background: `conic-gradient(${graph3})`}}></div>
                </div>
                <div className="graph_title">
                Recurring/Federal
                <div className="pie" style={{background: `conic-gradient(${graph4})`}}></div>
                </div>
            </div>
                    {
                        ads?
                        <div><script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3199046355212507"
                        crossorigin="anonymous"></script></div>
                        : <div></div>
                    }
        </div>
    )
}
export default VariableE