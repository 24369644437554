import React, {useState, useEffect} from 'react'
import axios from 'axios'
import './supply.css'
import create from "../sf/req/ce.js"
import deleteC from "../sf/req/de.js"
import edit from "../sf/req/dt.js"
import sortThis from "../sf/st.js"
import createGraph from '../sf/cg.js'
const link = process.env.REACT_APP_S
const Supply = () =>{
    const [data, setData] = useState([["Name", ""],["Category", ""],["Qauntity In Stock", 0],["Quantity to Order", 0],["Quantity Ordered", 0],["Supplier", ""],["Unit Price", 0],["Status", ""],["Last Order Date", ""],["Product Description", ""],["Product Store ID", ""],["Estimated Arrival", ""]])
    const selection = ["Name","Category","Quantity In Stock","Quantity to Order","Quantity Ordered","Supplier","Unit Price","Status","Last Order Date","Product Description","Product Store ID","Estimated Arrival"]
    const [retrieve, setRetrieved] = useState([])
    const [total, setTotals] = useState("")
    const [graph, setGraph] = useState("")
    const [errors, setErrors] = useState("")
    const setDataa = (e,t) =>{
        e.preventDefault()
        if(t[0].length > 25 || t[1].length > 25){
            setErrors(`${t[0]} cannot be greater then 25 Characters.`)
            return null
        }
        const copy = [...data]
        switch(t[0]){
            case selection[0]:
                copy[0] = t
            break;
            case selection[1]:
                copy[1] = t
            break;
            case selection[2]:
                if(!/^[0-9]+$/.test(t[1]) && t[1] !== ""){
                    setErrors(`${t[0]} must be a number`)
                    return null
                }
                setErrors("")
                copy[2] = t
            break;
            case selection[3]:
                if(!/^[0-9]+$/.test(t[1]) && t[1] !== ""){
                    setErrors(`${t[0]} must be a number`)
                    return null
                }
                setErrors("")
                copy[3] = t
            break;
            case selection[4]:
                if(!/^[0-9]+$/.test(t[1]) && t[1] !== ""){
                    setErrors(`${t[0]} must be a number`)
                    return null
                }
                setErrors("")
                copy[4] = t
            break;
            case selection[5]:
                copy[5] = t
            break;
            case selection[6]:
                if(!/^[0-9]+$/.test(t[1]) && t[1] !== ""){
                    setErrors(`${t[0]} must be a number`)
                    return null
                }
                setErrors("")
                copy[6] = t
            break;
            case selection[7]:
                copy[7] = t
            break;
            case selection[8]:
                copy[8] = t
            break;
            case selection[9]:
                copy[9] = t
            break;
            case selection[10]:
                copy[10] = t
            break;
            case selection[11]:
                copy[11] = t
            break;
            default:
                return false;
        }
        setData([...copy])
    }
    useEffect(() => {

        getData("",0,2)

    },[])
    const clear = (request) =>{
        setErrors(request)
        setData([["Name", ""],["Category", ""],["Qauntity In Stock", 0],["Quantity to Order", 0],["Quantity Ordered", 0],["Supplier", ""],["Unit Price", 0],["Status", ""],["Last Order Date", ""],["Product Description", ""],["Product Store ID", ""],["Estimated Arrival", ""]])
    }
    const [flip, setFlip] = useState(0)
    const sortInfo = async(e, choice) =>{
      e.preventDefault()
      if(choice === 1){
        //Name
        const copy = retrieve
        const sorted = await sortThis(copy, "Name", `2${flip}`)
        setRetrieved(sorted)
      }
      if(choice === 2){
        //Quantity in stock
        const copy = retrieve
        const sorted = await sortThis(copy, "Quantity_in_stock", `1${flip}`)
        setRetrieved(sorted)
      }
      if(choice === 3){
        //Quantity to order
        const copy = retrieve
        const sorted = await sortThis(copy, "Quantity_to_order", `1${flip}`)
        setRetrieved(sorted)
      }
      if(choice === 4){
        //Supplier
        const copy = retrieve
        const sorted = await sortThis(copy, "Supplier", `2${flip}`)
        setRetrieved(sorted)
      }
      if(flip === 0){
        setFlip(1)
      }
      if(flip === 1){
        setFlip(0)
      }
    }
    const getData = async (content, choice, request) =>{
        try{
            const getAllLinks = [process.env.REACT_APP_VARIABLE_GL1,process.env.REACT_APP_VARIABLE_GL2,process.env.REACT_APP_VARIABLE_GL3,process.env.REACT_APP_VARIABLE_GL4,process.env.REACT_APP_VARIABLE_GL5,process.env.REACT_APP_VARIABLE_GL6,process.env.REACT_APP_VARIABLE_GL7,process.env.REACT_APP_VARIABLE_GL8,process.env.REACT_APP_VARIABLE_GL9,process.env.REACT_APP_VARIABLE_GL10,process.env.REACT_APP_VARIABLE_GL11,process.env.REACT_APP_VARIABLE_GL12,process.env.REACT_APP_VARIABLE_GL13,process.env.REACT_APP_VARIABLE_GL14]
            const getOneLinks = [process.env.REACT_APP_VARIABLE_GO1,process.env.REACT_APP_VARIABLE_GO2,process.env.REACT_APP_VARIABLE_GO3,process.env.REACT_APP_VARIABLE_GO4,process.env.REACT_APP_VARIABLE_GO5,process.env.REACT_APP_VARIABLE_GO6,process.env.REACT_APP_VARIABLE_GO7,process.env.REACT_APP_VARIABLE_GO8,process.env.REACT_APP_VARIABLE_GO9,process.env.REACT_APP_VARIABLE_GO10,process.env.REACT_APP_VARIABLE_GO11,process.env.REACT_APP_VARIABLE_GO12,process.env.REACT_APP_VARIABLE_GO13,process.env.REACT_APP_VARIABLE_GO14]
            const uc = [sessionStorage.getItem(process.env.REACT_APP_VARIABLE_B),sessionStorage.getItem(process.env.REACT_APP_VARIABLE_C)]
            switch(choice){
                case 0:
                    const res = await axios.post(`${link}${getAllLinks[request]}`,
                    {
                        k : process.env.REACT_APP_K,
                        'kk': uc[0],
                        'kk1': uc[1]
                    })  
                    if(!res.data.request){
                        setErrors("Please Login or Create an Account!")
                        return null
                    }
                    setRetrieved(res.data.item)
                    setTotals(totals(res.data.item))
                    setGraph(createGraph(res.data.item, "Supplier", "Quantity_in_stock"))
                    return null
                case 1: 
                    const res2 = await axios.post(`${link}${getOneLinks[request]}`,
                    {
                        k : process.env.REACT_APP_K,
                        content,
                        'kk': uc[0],
                        'kk1': uc[1]
                    })     
                    if(!res2.data.request){
                        setErrors("Please Login or Create an Account!")
                        return null
                    }
                    setData([["Name", res2.data.item.item.Name],["Category",  res2.data.item.item.Category],["Qauntity In Stock",  res2.data.item.item.Quantity_in_stock],["Quantity to Order",  res2.data.item.item.Quantity_to_order],["Quantity Ordered",  res2.data.item.item.Quantity_ordered],["Supplier",  res2.data.item.item.Supplier],["Unit Price",  res2.data.item.item.Unit_price],["Status",  res2.data.item.item.Status],["Last Order Date",  res2.data.item.item.last_order_Date],["Product Description",  res2.data.item.item.Product_description],["Product Store ID",  res2.data.item.item.Product_store_id],["Estimated Arrival",  res2.data.item.item.Estimated_Arrival], ["_id",  res2.data.item._id]])
                    return null
                default:
                    return [false, false]  
            }
        }catch{
            return [false, "Something went wrong"]
        } 
    }
    const processData = async(e, content, choice) =>{
        e.preventDefault()
        const uc = [sessionStorage.getItem(process.env.REACT_APP_VARIABLE_B),sessionStorage.getItem(process.env.REACT_APP_VARIABLE_C)]
        switch (choice){
            case 0:
                const res0 = await create(uc, content, 1)
                if(res0){
                    setErrors("Created")
                    clear("Created")
                    getData("", 0,2)
                    return null
                }else{
                    setErrors("Error creating")
                    return null
                }
                break;
            case 1:
                const res1 = await deleteC(uc, content, 1)
                if(res1){
                    setErrors("Removed")
                    clear("Removed")
                    getData("", 0, 2)
                    return null
                }else{
                    setErrors("Error removing")
                    return null
                }
                break;
            case 2:
                const res2 = await edit(uc, content, 1)
                if(res2){
                    setErrors("Updated")
                    clear("Updated")
                    getData("", 0,2)
                    return null
                }else{
                    setErrors("Error updating")
                    return null
                }
                break;
            default: 
                return false
        }
    }
    const totals = (retrieve) =>{
        var total1 = 0
        var total2 = 0
        var total3 = 0
        var total4 = 0
            for(let i =0; i < retrieve.length; i++){
                total1 = total1 + parseInt(retrieve[i].item.Quantity_in_stock)
                total2 = total2 + (parseInt(retrieve[i].item.Quantity_in_stock) * parseInt(retrieve[i].item.Unit_price))
                total3 = total3 + parseInt(retrieve[i].item.Quantity_ordered)
                total4 = total4 + parseInt(retrieve[i].item.Quantity_ordered)
            }
            return [total1,total2,total3,total4]
    }
    const c = sessionStorage.getItem("key")
    return(
        <div className="supply_container">
            <div className="supplyList">
                <div className="item_container">
                        <div className="item_span" onClick={(e)=>{sortInfo(e,1)}}>Name</div>
                        <div className="item_span" onClick={(e)=>{sortInfo(e,2)}}>In stock</div>
                        <div className="item_span" onClick={(e)=>{sortInfo(e,3)}}>To order</div>
                        <div className="item_span" onClick={(e)=>{sortInfo(e,4)}}>Supplier</div>
                </div>
                {
                    retrieve.map((item, id)=>{
                        return (
                            <div key={id} className="item_container">
                                <div className="item_span">{item.item.Name} </div>
                                <div className="item_span">{item.item.Quantity_in_stock}</div>
                                <div className="item_span">{item.item.Quantity_to_order}</div>
                                <div className="item_span">{item.item.Supplier}</div>
                            
                                <button className="button-2" onClick={(e)=>{getData(item._id, 1, 1)}}>Edit</button>
                                <button className="button-2" onClick={(e)=>{processData(e, item._id, 1)}}>Remove</button>
                            </div>
                        )
                    })
                }
            </div>
            <div className="supplyForm">
                <div className="supply_uin">
                    <div className="supply_label">
                        {data[0][0]} 
                    </div>
                    <input type="text" className="supply_input" onChange={(e)=>{setDataa(e,[selection[0] , e.target.value])}} value={data[0][1]}/>
                </div>     
                <div className="supply_uin">
                    <div className="supply_label">
                        {data[1][0]}
                    </div>
                    <input type="text" className="supply_input" onChange={(e)=>{setDataa(e,[selection[1] , e.target.value])}} value={data[1][1]}/>
                </div>   
                <div className="supply_uin">
                    <div className="supply_label">
                        {data[2][0]} 
                    </div>
                    <input type="text" className="supply_input" onChange={(e)=>{setDataa(e,[selection[2] , e.target.value])}} value={data[2][1]}/>
                </div>   
                <div className="supply_uin">
                    <div className="supply_label">
                        {data[3][0]} 
                    </div>
                    <input type="text" className="supply_input" onChange={(e)=>{setDataa(e,[selection[3] , e.target.value])}} value={data[3][1]}/>
                </div>   
                <div className="supply_uin">
                    <div className="supply_label">
                        {data[4][0]} 
                    </div>
                    <input type="text" className="supply_input" onChange={(e)=>{setDataa(e,[selection[4] , e.target.value])}} value={data[4][1]}/>
                </div>   
                <div className="supply_uin">
                    <div className="supply_label">
                        {data[5][0]} 
                    </div>
                    <input type="text" className="supply_input" onChange={(e)=>{setDataa(e,[selection[5] , e.target.value])}} value={data[5][1]}/>
                </div>   
                <div className="supply_uin">
                    <div className="supply_label">
                        {data[6][0]} 
                    </div>
                    <input type="text" className="supply_input" onChange={(e)=>{setDataa(e,[selection[6] , e.target.value])}} value={data[6][1]}/>
                </div>   
                <div className="supply_uin">
                    <div className="supply_label">
                        {data[7][0]} 
                    </div>
                    <select name="" id="" onChange={(e)=>{setDataa(e,[selection[7] , e.target.value])}} value={data[7][1]}>
                        <option value="0">Enabled</option>
                        <option value="1">Disabled</option>
                    </select>
                </div>   
                <div className="supply_uin">
                    <div className="supply_label">
                        {data[8][0]} 
                    </div>
                    <input type="date" className="supply_input" onChange={(e)=>{setDataa(e,[selection[8] , e.target.value])}} value={data[8][1]}/>
                </div>   
                <div className="supply_uin">
                    <div className="supply_label">
                        {data[9][0]} 
                    </div>
                    <input type="text" className="supply_input" onChange={(e)=>{setDataa(e,[selection[9] , e.target.value])}} value={data[9][1]}/>
                </div>   
                <div className="supply_uin">
                    <div className="supply_label">
                        {data[10][0]} 
                    </div>
                    <input type="text" className="supply_input" onChange={(e)=>{setDataa(e,[selection[10] , e.target.value])}} value={data[10][1]}/>
                </div>   
                <div className="supply_uin">
                    <div className="supply_label">
                        {data[11][0]} 
                    </div>
                    <input type="date" className="supply_input" onChange={(e)=>{setDataa(e,[selection[11] , e.target.value])}} value={data[11][1]}/>
                </div>   
                {
                    c?
                    <div>
                        <button className="button-7" onClick={(e)=>{processData(e,data, 0)}}>Create</button>   
                        <button className="button-7" onClick={(e)=>{clear("")}}>Clear</button>  
                        <button className="button-7" onClick={(e)=>{processData(e,data, 2)}}>Update</button> 
                        
                    </div>
                    : <div></div>
                }
                <div>
                    <div className="error">
                        {errors}
                    </div>
                </div>
            </div>
            <div className="supplyTotals">
                <div>In Stock : {total[0]}</div>
                <div>Items : {retrieve.length}</div>
                <div>Total Value : ${total[1]}</div>
                <div>Quantity on Order : {total[2]}</div>
            </div>
            <div className="supplyGraphs">
                Supplier / Quantity in Stock
                <div className="pie" style={{background: `conic-gradient(${graph})`}}></div>
            </div>
        </div>
    )
}
export default Supply