
import Nav from '../native/nav_bar.jsx'
const Feedback_form = () =>{
    return(
        <div>
            <Nav/>
            <div className="description_container">
               <center><h1>Submit Feedback</h1>
                <h2>Submit feedback @ info@cloudease.app</h2>  
                </center></div>
        </div>
    )
}
export default Feedback_form