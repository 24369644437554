import React, {useState} from 'react'
import { useNavigate} from "react-router-dom";
import "./buttons.css"
import "./background.css"
import "./home.css"
import axios from 'axios'
import {valid0, valid1} from '../sf/re.js'
import Nav from './nav_bar.jsx'
const link = process.env.REACT_APP_S
const ads = process.env.REACT_APP_BUILD
const Home = () =>{
    let navigate = useNavigate();
    const footer =  <div className="cp">All Rights Reserved 2023 © Device Information Limited
                    </div>
    const [data, setData] = useState("")
    const [data1, setData1] = useState("")
    const [error, setError] = useState("")

    const runLogin = async(e) =>{
        e.preventDefault()
        if(!valid0.test(data)){
            setError("Username [6-8 Characters, Letters only]")
            return null
        }
        if(!valid1.test(data1)){
            setError("Password [8-16 Characters, 1 Upper, 1 Lower, 1 Special, 1 Number]")
            return null
        }

        setError("")
        setError("Logging in")
        const csrfToken = "false";
        const csrfToken1 = data;
        const csrfToken2 = data1;
        setData("")
        setData1("")
        const uc = [data,data1]
        const bcrypt = require('bcryptjs');
        const salt = await bcrypt.genSalt(10);
        const h = await bcrypt.hash(uc[1], salt);
        await axios.post(`${link}${process.env.REACT_APP_VARIABLE_A}`, 
        {
                k : process.env.REACT_APP_K,
                'kk': csrfToken,
                'kk1': csrfToken1,
                'kk2': csrfToken2,
                'kk3': h
        })
        .then(res=>{
            if(res.data.item.lo){
                setData("")
                setData1("")
                setError("login success")
                sessionStorage.clear()
                sessionStorage.setItem("key", res.data.item.lo)
                sessionStorage.setItem(process.env.REACT_APP_VARIABLE_B, res.data.item.us)
                sessionStorage.setItem(process.env.REACT_APP_VARIABLE_C, res.data.item.la)
                navigate('/Menu')
                return null
            }
            if(res.data.item.request){
                setError("Account created. Please login")
                return null
            }else{
                setError("Please check credentials!")
                return false
            }
        })
        .catch(err=>{
            setError("Sorry, something went wrong")
            return false;
        })
        }
                return(
                    <div>
                        <Nav/>
                    <div className="bg_home">
                        <div className="login_container">
                            <div className="logo">
                                Cloud Ease - Small Business Resource Planning
                                <div className="bottom-right-quarter-circle"></div>
                            </div>
                            <div className="box"></div>
                            <div className="intro">
                                PLEASE LOG IN TO CREATE ACCOUNT
                            </div>
                            <div className="login_uin">
                                <div className="login_label">
                                    <label htmlFor="">Username</label>
                                </div>
                                <div className="login_input">
                                    <input type="text" onChange={(e)=>{setData(e.target.value)}} value={data}/>
                                </div>
                            </div>
                            <div className="login_uin">
                                <div className="login_label">
                                    <label htmlFor="">Password</label>
                                </div>
                                <div className="login_input">
                                    <input type="password"  onChange={(e)=>{setData1(e.target.value)}} value={data1}/>
                                </div>
                            </div>
                            <div className="version">
                                {process.env.REACT_APP_VERSION}
                            </div>
                            <button className="login_button" onClick={(e) =>{runLogin(e)}}>
                                Log In
                            </button>
                            <div className="error">
                                {error}
                            </div>
                        </div>
                        {footer}
                        {
                            ads?
                            <div><script type="text/javascript"> var infolinks_pid = 3400834; var infolinks_wsid = 0; </script> <script type="text/javascript" src="//resources.infolinks.com/js/infolinks_main.js"></script></div>
                            : <div></div>
                        }
                        </div>
                    </div>
                ) 
    }
export default Home