
import React, {useState} from 'react'
import { useNavigate } from "react-router-dom";
import './accounting.css'
import Assets from './assets.jsx'
import Earnings from './earnings.jsx'
import FixedE from './fixed_expenses.jsx'
import Liabilities from './liabilities.jsx'
import Reciepts from './reciepts.jsx'
import Transportation from './transportation.jsx'
import VariableE from './variable_expenses.jsx'
const Accounting_menu = () =>{
    let navigate = useNavigate();
    const home = <button className="button-66" onClick={(e)=>{e.preventDefault();navigate('/Menu')}}>Home</button>
    const [accountChoice, setAccountChoice] = useState("accounting_menu")
    const back = <button className="button-37" onClick={()=>{setAccountChoice("accounting_menu")}}>Accounting Menu</button>
    const names = ["Assets", "Earnings", "Fixed Expenses", "Liabilities", "Reciepts", "Transportation", "Variable Expenses"]
    const ads = process.env.REACT_APP_BUILD
    switch (accountChoice) {
        case 0: 
            return(
                <div>
                    {back}
                    <h1>{names[0]}</h1>
                    <Assets/>
                    <iframe className="background_2" width="100%" height="1080" src="https://api.maptiler.com/maps/33ac29e1-786b-4779-b142-2ca40194b55a/?key=pqD8WLIbS6twjarh6dJO#3.3/35.91279/-96.30308"></iframe>
                </div>
            )
        case 1: 
            return(
                <div>
                    {back}
                    <h1>{names[1]}</h1>
                    <Earnings/>
                    <iframe className="background_2" width="100%" height="1080" src="https://api.maptiler.com/maps/33ac29e1-786b-4779-b142-2ca40194b55a/?key=pqD8WLIbS6twjarh6dJO#3.3/35.91279/-96.30308"></iframe>
                    
                </div>
            )
        case 2: 
            return(
                <div>

                    {back}
                    <h1>{names[2]}</h1>
                    <FixedE/>
                    <iframe className="background_2" width="100%" height="1080" src="https://api.maptiler.com/maps/33ac29e1-786b-4779-b142-2ca40194b55a/?key=pqD8WLIbS6twjarh6dJO#3.3/35.91279/-96.30308"></iframe>
                    
                </div>
            )
        case 3: 
            return(
                <div>

                    {back}
                    <h1>{names[3]}</h1>
                    <Liabilities/>
                    <iframe className="background_2" width="100%" height="1080" src="https://api.maptiler.com/maps/33ac29e1-786b-4779-b142-2ca40194b55a/?key=pqD8WLIbS6twjarh6dJO#3.3/35.91279/-96.30308"></iframe>
                    
                </div>
            )
        case 4: 
            return(
                <div>

                    {back}
                    <h1>{names[4]}</h1>
                    <Reciepts/>
                    <iframe className="background_2" width="100%" height="1080" src="https://api.maptiler.com/maps/33ac29e1-786b-4779-b142-2ca40194b55a/?key=pqD8WLIbS6twjarh6dJO#3.3/35.91279/-96.30308"></iframe>
                    
                </div>
            )
        case 5: 
            return(
                <div>

                    {back}
                    <h1>{names[5]}</h1>
                    <Transportation/>
                    <iframe className="background_2" width="100%" height="1080" src="https://api.maptiler.com/maps/33ac29e1-786b-4779-b142-2ca40194b55a/?key=pqD8WLIbS6twjarh6dJO#3.3/35.91279/-96.30308"></iframe>
                    
                </div>
            )
        case 6: 
            return(
                <div>

                    {back}
                    <h1>{names[6]}</h1>
                    <VariableE/>
                    <iframe className="background_2" width="100%" height="1080" src="https://api.maptiler.com/maps/33ac29e1-786b-4779-b142-2ca40194b55a/?key=pqD8WLIbS6twjarh6dJO#3.3/35.91279/-96.30308"></iframe>
                    
                </div>
            )
        default:
            return (
                <div className="whole_container">
                    {home}
                    <div className="accounting_button_container">
                        <button className="account_b" onClick={()=>{setAccountChoice(0)}}>Assets</button>
                        <button className="account_b" onClick={()=>{setAccountChoice(1)}}>Earnings</button>
                        <button className="account_b" onClick={()=>{setAccountChoice(2)}}>Fixed Expenses</button>
                        <button className="account_b" onClick={()=>{setAccountChoice(3)}}>Liabilities</button>
                        <button className="account_b" onClick={()=>{setAccountChoice(4)}}>Reciepts</button>
                        <button className="account_b" onClick={()=>{setAccountChoice(5)}}>Transportation</button>
                        <button className="account_b" onClick={()=>{setAccountChoice(6)}}>Variable Expenses</button>
                    </div>
                    <div className="description_container">
                    <h3> The accounting balance sheet is a financial statement that provides a snapshot of a company's financial position at a specific point in time. It presents the company's assets, liabilities, and shareholders' equity, allowing stakeholders to assess its financial health and performance. The benefits of an accounting balance sheet include:</h3>
                    <h2>1. Financial Analysis:</h2> The balance sheet helps investors, creditors, and other stakeholders analyze a company's liquidity, solvency, and overall financial stability. By examining the relationship between assets and liabilities, they can evaluate the company's ability to meet its short-term and long-term obligations.
                    <h2>2. Decision Making:</h2> The balance sheet provides crucial information for decision-making processes. It helps management determine the allocation of resources, assess the need for financing or investment, and identify areas for improvement or cost reduction.
                    <h2>3. Transparency and Accountability:</h2> The balance sheet promotes transparency by disclosing a company's financial position to external parties. It allows investors and creditors to make informed decisions based on accurate and reliable information. Additionally, it holds management accountable for the stewardship of company resources.                   
                    </div>
                    {
                        ads?
                        <div><script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3199046355212507"
                        crossorigin="anonymous"></script></div>
                        : <div></div>
                    }
                
                </div>
            )
        }
}
export default Accounting_menu