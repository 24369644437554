import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Edit_budget from './edit_budget.js'
const link = process.env.REACT_APP_S
const Budget = () =>{
    const [choice, setChoice] = useState("")
    const [data2, setData2] = useState("")
    const [errors, setErrors] = useState("")
    const [retrieve, setRetrieved] = useState([])
    const back = <button className="button-37" onClick={()=>{setChoice("")}}>Budgets</button>
   
    const getAll = async(request)=>{
        const getAllLinks = ["/recruitment/all"]
        const uc = [sessionStorage.getItem(process.env.REACT_APP_VARIABLE_B),sessionStorage.getItem(process.env.REACT_APP_VARIABLE_C)]
        const res = await axios.post(`${link}${getAllLinks[request]}`,
        {
            k : process.env.REACT_APP_K,
            'kk': uc[0],
            'kk1': uc[1]
        })       
        if(!res.data.request){
            setErrors("")
            return false
        }
        setRetrieved(res.data.item)
        return null
    }

    useEffect(()=>{

        getAll(0)

    },[])

   switch(choice){
    case 0:
        return(
            <div>
                {back}
                <Edit_budget id={data2}/>
            </div>
        )
    default:
        return(
            <div className="whole_container">
                <div className="budget_container">
                    <div className="budget_list">
                            <div className="item_container">
                                <div className="item_span">Budget Name</div>  
                                <div className="item_span">Month</div>
                                <div className="item_span">Year</div>
                            </div>
                            {retrieve.map((item, id)=>{
                                return(
                                    <div>
                                        <div className="item_container">
                                            <div className="item_span">{}</div>  
                                            <div className="item_span">{}</div>
                                            <div className="item_span"></div>
                                            <button className="button-2" onClick={()=>{setChoice(0); setData2(item._id)}}>Edit</button>
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </div>
        )  
    }
}
export default Budget