import React, {useState} from 'react'
import { useNavigate } from "react-router-dom";
import Devices from './devices.js'

const Device_menu = () =>{

    let navigate = useNavigate();
    const home = <button className="button-66" onClick={(e)=>{e.preventDefault();navigate('/Menu')}}>Home</button>
    const [choice ,setChoice] = useState("menu")
    const back = <button className="button-37" onClick={(e)=>{setChoice("menu")}}>Equipment Menu</button>
    const ads = process.env.REACT_APP_BUILD
    switch(choice){
        case "devices":
            return(
                <div className="whole_container">
                    {back}
                    <Devices/>
                    <iframe className="background_2" width="100%" height="1080" src="https://api.maptiler.com/maps/33ac29e1-786b-4779-b142-2ca40194b55a/?key=pqD8WLIbS6twjarh6dJO#3.3/35.91279/-96.30308"></iframe>
                    
                </div>
            )
        default:
            return(
                <div className="whole_container">
                    {home}
                    <div className="dss_container">
                        <button className="device_b" onClick={()=>{setChoice("devices")}}>Manage Equipment</button>
                    </div> 
                    <div className="description_container">
                       <h3> Managing and tracking equipment and their status offers numerous benefits, including the following top 3:</h3>
                        <h2>1. Improved efficiency:</h2> By keeping track of equipment, organizations can optimize their usage, reduce downtime, and ensure that resources are allocated effectively.
                        <h2>2. Cost savings:</h2> Effective management and tracking help prevent loss, theft, or misplacement of equipment, reducing the need for replacements and minimizing financial losses.
                        <h2> 3. Enhanced productivity:</h2> Knowing the status and location of equipment enables quick retrieval when needed, minimizing delays and improving overall productivity.
                    </div>
                    {
                        ads?
                        <div><script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3199046355212507"
                        crossorigin="anonymous"></script></div>
                        : <div></div>
                    }
                </div>
            )
    }
}
export default Device_menu