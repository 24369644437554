import React, {useState} from 'react'
import { useNavigate } from "react-router-dom";
import './crm.css'
import Manage_contacts from './manage_contacts.js'
import Manage_leads from './manage_lead.js'
import Infopromo from './manage_info_promo.jsx'

const CRM_menu = () =>{
    let navigate = useNavigate();
    const home = <button className="button-66" onClick={(e)=>{e.preventDefault();navigate('/Menu')}}>Home</button>
    const [choice, setChoice] = useState("")
    const ads = process.env.REACT_APP_BUILD
    const back = <button onClick={()=>{setChoice("")}} className="button-37">Relations Menu</button>
    switch(choice){
    case 0:
        return(
            <div>
                {back}
                <h1>Contacts</h1>
                <Manage_contacts/>
                <iframe className="background_2" width="100%" height="1080" src="https://api.maptiler.com/maps/33ac29e1-786b-4779-b142-2ca40194b55a/?key=pqD8WLIbS6twjarh6dJO#3.3/35.91279/-96.30308"></iframe>
                    
            </div>
        )
        break;
    case 1:
        return(
            <div>
                {back}
                <h1>Leads</h1>
                <Manage_leads/>
                <iframe className="background_2" width="100%" height="1080" src="https://api.maptiler.com/maps/33ac29e1-786b-4779-b142-2ca40194b55a/?key=pqD8WLIbS6twjarh6dJO#3.3/35.91279/-96.30308"></iframe>
                    
            </div>
        )
        break;
    case 2:
        return(
            <div>
                {back}
                <h1>Information and Promotion</h1>
                <Infopromo/>
                <iframe className="background_2" width="100%" height="1080" src="https://api.maptiler.com/maps/33ac29e1-786b-4779-b142-2ca40194b55a/?key=pqD8WLIbS6twjarh6dJO#3.3/35.91279/-96.30308"></iframe>
                    
            </div>
        )
        break;
    case 3:
        break;
    default:
        return(
            <div className="whole_container">
                {home}
                <div className="crm_button_container">
                    <button className="crm_b" onClick={()=>{setChoice(0)}}>Manage Contacts</button>
                    <button className="crm_b" onClick={()=>{setChoice(1)}}>Manage Leads</button>
                    <button className="crm_b" onClick={()=>{setChoice(2)}}>Manage Promotions and Information</button>
                    
                    {
                        /*
                            <button className="crm_b" onClick={()=>{setChoice(2)}}>Sales Automation</button>
                            <button className="crm_b" onClick={()=>{setChoice(3)}}>Customer Support</button>
                        */
                    }
                </div>
                <div className="description_container">
                <h3>There are several benefits of using an application to track contacts and leads. The top three benefits include:</h3>
                <h2>1. Efficient organization: </h2>An application allows for systematic organization of contact and lead information, making it easier to manage and access relevant data. It provides a centralized platform to store and update contact details, communication history, and lead status, ensuring that important information is readily available.
                <h2>2. Improved productivity:</h2> By automating various tasks such as data entry, follow-ups, and reminders, an application helps streamline the lead management process. This automation saves time and reduces manual effort, allowing sales teams to focus on more critical activities like nurturing leads and closing deals.
                <h2>3. Enhanced analytics and reporting:</h2> Tracking contacts and leads through an application enables the generation of comprehensive reports and analytics. These insights provide valuable information on lead conversion rates, sales performance, and customer behavior. By analyzing this data, businesses can make informed decisions, identify areas for improvement, and optimize their sales strategies.  
                </div>
                    {
                        ads?
                        <div><script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3199046355212507"
                        crossorigin="anonymous"></script></div>
                        : <div></div>
                    }
            </div>
        )
    }
}
export default CRM_menu