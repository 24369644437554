import React, {useState} from 'react'

const Gallery = () =>{

  /*
    Get IDs of images
    Get image by ID
  */

  const [images, setImages] = useState(["","",""])

  const [img, setImage] = useState(1)

  const f_ward = async(e) => {
    e.preventDefault()
    let copy = img
    if(copy < images.length){
      copy = copy + 1
    }
    setImage(copy)

  }

  const b_ward = async(e) => {
    e.preventDefault()
    let copy = img
    if(copy > 1){
      copy = copy - 1
    }
    setImage(copy)

  }

  const get_img = async() =>{
      /*
        Go to api with ?? 3 items
        Size image to contain in container

      */

  }

  /*
    Style buttons
  */

  return (
    <div>
        <div className="gallery">

          <button className="b_ward btn" onClick={()=>{setImage(1)}}> {"<<<"} </button>
          <button className="b_ward btn" onClick={(e)=>{b_ward(e)}}> {"<"} </button>
            <p className="c_img"> {`${img}`} / {`${images.length}`} </p>
          <button className="f_ward btn" onClick={(e)=>{f_ward(e)}}> {">"} </button>
          <button className="f_ward btn" onClick={()=>{setImage(images.length)}}> {">>>"} </button>

        </div>
    </div>

  );
}
export default Gallery