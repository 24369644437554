
import React, {useState} from 'react'
import { useNavigate } from "react-router-dom";
import './orders.css'
import All_orders from './all_orders.js'
import One_order from './single_order.js'
import New_order from './new_order.js'

const Orders_menu = (props) =>{

    let navigate = useNavigate();
    const home = <button className="button-66" onClick={(e)=>{e.preventDefault();navigate('/Menu')}}>Home</button>
    const [orderChoice, setOrderChoice] = useState("order_menu")
    const ads = process.env.REACT_APP_BUILD
    const back = <button className="button-37" onClick={()=>{setOrderChoice("order_menu")}}>Order Menu</button>
    switch(orderChoice){
        case 0:
            return(
                <div className="whole_container">
                    {back}
                    <All_orders/>
                    <iframe className="background_2" width="100%" height="1080" src="https://api.maptiler.com/maps/33ac29e1-786b-4779-b142-2ca40194b55a/?key=pqD8WLIbS6twjarh6dJO#3.3/35.91279/-96.30308"></iframe>
                    
                </div>
            )
        case 1:
            return(
                <div className="whole_container">
                    {back}
                    <New_order/>
                    <iframe className="background_2" width="100%" height="1080" src="https://api.maptiler.com/maps/33ac29e1-786b-4779-b142-2ca40194b55a/?key=pqD8WLIbS6twjarh6dJO#3.3/35.91279/-96.30308"></iframe>
                    
                </div>
            )
        case 2:
            return(
                <div className="whole_container">
                    {back}
                    <One_order/>
                    <iframe className="background_2" width="100%" height="1080" src="https://api.maptiler.com/maps/33ac29e1-786b-4779-b142-2ca40194b55a/?key=pqD8WLIbS6twjarh6dJO#3.3/35.91279/-96.30308"></iframe>
                    
                </div>
            )
        
        default:
            return(
                <div className="whole_container">
                    {home}
                    <div className="order_button_container">
                        <button className="order_b" onClick={()=>{setOrderChoice(0)}}>Manage Orders</button>
                        <button className="order_b" onClick={()=>{setOrderChoice(1)}}>Create Order</button>
                        <button className="order_b" onClick={()=>{setOrderChoice(2)}}>Find Order</button>
                    </div>

                <div className="description_container">
                    <h3>Using an application to manage and track customer orders offers numerous benefits, including:</h3>
                    <h2>1. Efficiency:</h2> An application streamlines the order management process, automating tasks such as order entry, inventory management, and invoicing. This reduces manual errors and saves time for both customers and employees.
                    <h2> 2. Accuracy:</h2> By centralizing order information in one system, applications ensure accurate data entry and minimize the risk of miscommunication or lost orders. Real-time updates also enable better visibility into order status.
                    <h2>3. Improved customer experience:</h2> Applications provide customers with self-service options to place orders, track shipments, and view order history. This enhances convenience and satisfaction while reducing the need for customer support.          
                </div>

                    {
                        ads?
                        <div><script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3199046355212507"
                        crossorigin="anonymous"></script></div>
                        : <div></div>
                    }
                </div>
            )
    }
}
export default Orders_menu