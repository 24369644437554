const createGraph = (objArr, Item1,Item2) =>{
/*
    Step 1 get Categories and totals
    Step 2 Create array of percentages
    Step 3 Create Graph
*/
    const gCat = (objArr, Name, Tot) => {

    const arr = []
        for(let i=0; i < objArr.length; i++){
            let found = false
            for(var j=0; j < arr.length; j++){
                const tempName = `${objArr[i]["item"][Name].normalize()}`
                if(tempName === arr[j].name){
                    found = true
                    break;
                }
            }
                if(!found){
                    arr.push({ name : objArr[i]["item"][Name], total : parseInt(objArr[i]["item"][Tot])})
                }else{
                    arr[j]["total"] = arr[j]["total"] + parseInt(objArr[i]["item"][Tot])
                }
            }
            return arr
    }

    const gPer =(myArr, name) =>{
    let newArr = []
    let total = 0

    myArr.map(item => {
        total = total+item[name];
    })

    for(var item of myArr){
            var temp = (item[name]/total) * 100
            const temp2 = temp.toString().slice(0,2)
            newArr.push(parseInt(temp2))
        }
    return sNum(newArr)
}

const sNum = (arrayIn) =>{
        arrayIn.sort((a, b) => {
            return a - b;
        });

        return arrayIn
}

function gRanCol() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
    }

    const printGraph = (arr) =>{
    let total = 0
    let graph = ``
        if(arr.length === 1){
            return `${gRanCol()} 0% 100%`
        }
        if(arr.length === 2){
                    graph = `${gRanCol()} ${arr[0]}%,`
                    graph = `${graph} ${gRanCol()} ${arr[0]}% ${arr[1]}%`
            
            
            return graph
        }
        else{
            for(let i=0; i< arr.length; i++){
                total = total + arr[i]
                if(i === 0){
                    graph = `${gRanCol()} ${total}%,`
                }
                if(i >= 1 && i < arr.length){
                    graph = `${graph} ${gRanCol()} ${total - arr[i]}% ${(arr[i] - arr[i])+total}%,`
                }
                if(i === arr.length-1){
                    graph = `${graph} ${gRanCol()} ${total}%`
                }
            }
        }
    return(graph)
    }
    const array = gCat(objArr, Item1, Item2)
    const finished = gPer(array, 'total')

    return(printGraph(finished))
}


export default createGraph