import React, {useState} from 'react'
import { useNavigate, Link } from "react-router-dom";
const Menu = () =>{
    let navigate = useNavigate();
    const [choice ,setChoice] = useState("menu")
    const c = sessionStorage.getItem("key")
    const logout = <button className="button-37" onClick={()=>{sessionStorage.clear(); navigate('/')}}>{c?
    <div>Logout</div>
    : <div>Create Account</div>
    }</button>
    const footer = <div className="cp">All Rights Reserved 2023 © Device Information Limited</div>
    switch(choice) {
        case "accounting" : 
            navigate('/Accounting');
            break;
        case "accounts": 
            navigate('/Account');
            break;
        case "orders" :
            navigate('/Orders');
            break;
        case "supply" :
            navigate('/Supply');
            break;
        case "devices" :
            navigate('/Devices');
            break;
        case "budget" :
            navigate('/Budget');
            break;
        case "hr" :
            navigate('/Human-resources');
            break;
        case "crm" :
            navigate('/Customer-relations');
            break;
        default:
            return (
                <div>
                    <div className="whole_container"> 
                    {logout}
                        <div className="menu_container">
                            <button className="menu_button accounting_button " onClick={(e)=>{setChoice("accounting")}}>
                            <div className="button_bg">Tracking</div> 
                            </button>
                            <button className="menu_button account_button" onClick={(e)=>{setChoice("accounts")}}>
                                <div className="button_bg">Account</div> 
                            </button>
                            <button className="menu_button device_button" onClick={(e)=>{setChoice("devices")}}>
                                <div className="button_bg">Equipment</div> 
                            </button>
                            <button className="menu_button order_button" onClick={(e)=>{setChoice("orders")}}>
                                <div className="button_bg">Orders</div> 
                            </button>
                            <button className="menu_button supply_button" onClick={(e)=>{setChoice("supply")}}>
                                <div className="button_bg">Supply</div> 
                            </button>
                            {
                                /* 
                                    <button className="menu_button budget_button" onClick={(e)=>{setChoice("budget")}}>
                                        <div className="button_bg">Budget</div> 
                                    </button>
                                */
                            }
                            <button className="menu_button crm_button" onClick={(e)=>{setChoice("crm")}}>
                                <div className="button_bg">CRM</div> 
                            </button>
                            <button className="menu_button hr_button" onClick={(e)=>{setChoice("hr")}}>
                                <div className="button_bg">HR</div> 
                            </button>
                        </div>
                    </div>
                    <div className="fci_container">
                        Benefits Guides :
                        <Link className="button-2" to={'/Accounting-benefits'}>Tracking</Link>
                        <Link className="button-2" to={'/Budgeting-benefits'}>Budgeting</Link>
                        <Link className="button-2" to={'/Customer-relations-benefits'}>CRM</Link>
                        <Link className="button-2" to={'/Equipment-management-benefits'}>Equipment</Link>
                        <Link className="button-2" to={'/Human-resources-benefits'}>Human Resources</Link>
                        <Link className="button-2" to={'/Order-management-beneifts'}>Orders</Link>
                        <Link className="button-2" to={'/Supply-management-benefits'}>Supply</Link>
                    </div>
                    {footer}
                </div>
            )
    }
}
export default Menu