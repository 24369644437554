import React, {useState, useEffect} from 'react'
import axios from 'axios'
import edit from "../sf/req/dt.js"
import sortThis from "../sf/st.js"
import Edit_order from './edit_order.js'
import createGraph from '../sf/cg.js'
import calculateDaysBetweenDates from '../sf/getDate.js'
import './orders.css'
const link = process.env.REACT_APP_S
const All_orders = () =>{
    const [retrieve, setRetrieved] = useState([])
    const [graph2, setGraph2] = useState("")
    const [total, setTotals] = useState("")
    const [errors, setErrors] = useState("")
    const [choice, setChoice] = useState(1)
        const [flip, setFlip] = useState(0)
        const sortInfo = async(e, choice) =>{
          e.preventDefault()
          if(choice === 1){
            //Entry Date
            const copy = retrieve
            const sorted = await sortThis(copy, "entry_date", `3${flip}`)
            setRetrieved(sorted)
          }
          if(choice === 2){
            //Priority
            const copy = retrieve
            const sorted = await sortThis(copy, "priority", `1${flip}`)
            setRetrieved(sorted)
          }
          if(choice === 3){
            //Deadline
            const copy = retrieve
            const sorted = await sortThis(copy, "dead_line", `3${flip}`)
            setRetrieved(sorted)
          }
          if(choice === 4){
            //Daysleft
            const copy = retrieve
            const sorted = await sortThis(copy, "days_left", `1${flip}`)
            setRetrieved(sorted)
          }
          if(choice === 5){
            //Tracking Id
            const copy = retrieve
            const sorted = await sortThis(copy, "tracking_id", `2${flip}`)
            setRetrieved(sorted)
          }
          if(choice === 6){
            //Description
            const copy = retrieve
            const sorted = await sortThis(copy, "description", `2${flip}`)
            setRetrieved(sorted)
          }
          if(choice === 7){
            //Status
            const copy = retrieve
            const sorted = await sortThis(copy, "order_status", `1${flip}`)
            setRetrieved(sorted)
          }
          if(flip === 0){
            setFlip(1)
          }
          if(flip === 1){
            setFlip(0)
          }
        }
    const clear = (request) =>{
        setErrors(request)
    }
    useEffect(() => {

        getData("", 0,11)
    
    }, [])
    const getData = async (content, choice, request) =>{
        try{
            const getAllLinks = [process.env.REACT_APP_VARIABLE_GL1,process.env.REACT_APP_VARIABLE_GL2,process.env.REACT_APP_VARIABLE_GL3,process.env.REACT_APP_VARIABLE_GL4,process.env.REACT_APP_VARIABLE_GL5,process.env.REACT_APP_VARIABLE_GL6,process.env.REACT_APP_VARIABLE_GL7,process.env.REACT_APP_VARIABLE_GL8,process.env.REACT_APP_VARIABLE_GL9,process.env.REACT_APP_VARIABLE_GL10,process.env.REACT_APP_VARIABLE_GL11,process.env.REACT_APP_VARIABLE_GL12,process.env.REACT_APP_VARIABLE_GL13,process.env.REACT_APP_VARIABLE_GL14]
            const getOneLinks = [process.env.REACT_APP_VARIABLE_GO1,process.env.REACT_APP_VARIABLE_GO2,process.env.REACT_APP_VARIABLE_GO3,process.env.REACT_APP_VARIABLE_GO4,process.env.REACT_APP_VARIABLE_GO5,process.env.REACT_APP_VARIABLE_GO6,process.env.REACT_APP_VARIABLE_GO7,process.env.REACT_APP_VARIABLE_GO8,process.env.REACT_APP_VARIABLE_GO9,process.env.REACT_APP_VARIABLE_GO10,process.env.REACT_APP_VARIABLE_GO11,process.env.REACT_APP_VARIABLE_GO12,process.env.REACT_APP_VARIABLE_GO13,process.env.REACT_APP_VARIABLE_GO14]
            const uc = [sessionStorage.getItem(process.env.REACT_APP_VARIABLE_B),sessionStorage.getItem(process.env.REACT_APP_VARIABLE_C)]
            switch(choice){
                case 0:
                    const res = await axios.post(`${link}${getAllLinks[request]}`,
                    {
                        k : process.env.REACT_APP_K,
                        'kk': uc[0],
                        'kk1': uc[1]
                    })  
                    if(!res.data.request){
                        setErrors("Please Login or Create an Account!")
                        return null
                    }
                    setRetrieved(res.data.item)
                    setGraph2(createGraph(res.data.item, "priority", "order_status"))
                    setTotals(totals(res.data.item))
                    return null
                case 1: 
                    const res2 = await axios.post(`${link}${getOneLinks[request]}`,
                    {
                        k : process.env.REACT_APP_K,
                        content,
                        'kk': uc[0],
                        'kk1': uc[1]
                    })  
                            
                    if(!res2.data.request){
                        setErrors("Please Login or Create an Account!")
                        return null
                    }
                    return null
                default:
                    return [false, false]  
            }
        }catch{
            return [false, "Something went wrong"]
        } 
    }
    const processData = async(e, content, choice) =>{
        e.preventDefault()
        const uc = [sessionStorage.getItem(process.env.REACT_APP_VARIABLE_B),sessionStorage.getItem(process.env.REACT_APP_VARIABLE_C)]
        switch (choice){
            case 2:
                const res2 = await edit(uc, content, 12)
                if(res2){
                    setErrors("Updated")
                    clear("Updated")
                    getData("", 0, 11)
                    return null
                }else{
                    setErrors("Error updating")
                    return null
                }
                break;
            default: 
                return false
        }
    }
    const totals = (retrieve) =>{
        var totalNew = 0
        var total25 = 0
        var total50 = 0
        var total75 = 0
        var totalCompleted = 0
        var totalCancelled= 0
        for(let i=0; i < retrieve.length; i++){
            switch(parseInt(retrieve[i].item.order_status)){
                case 0:
                    totalNew++
                    break;
                case 1:
                    total25++
                    break;
                case 2:
                    total50++
                    break;
                case 3:
                    total75++
                    break;
                case 4: 
                    totalCompleted++
                    break;
                case 5:  
                    totalCancelled++
                    break;
            default:
                return null
            }
        }
        return [totalNew ,total25 ,total50 ,total75 ,totalCompleted ,totalCancelled]
    }
    const getSelect = (info) =>{
        switch(info){
            case "0":
                return "1 Year <"
            case "1":
                return "6 Months <"
            case "2":
                return "1 Month"
            case "3":
                return "3-4 Weeks"
            case "4":
                return "2-3 Weeks"
            case "5":
                return "Urgent - Within 1 Week"
            default:
                return null
        }
    }
    const getSelect2 = (info) =>{
        switch(info){
            case "0":
                return "Cancelled"
            case "1":
                return "New"
            case "2":
                return "25%"
            case "3":
                return "50%"
            case "4":
                return "75%"
            case "5":
                return "Completed"
            default:
                return null
        }
    }
    var today = new Date();
    var date = today.getDate();
    var month = today.getMonth() + 1; 
    var year = today.getFullYear();
    var formattedDate = month + '/' + date + '/' + year;
    const [data2, setData2] = useState("")
    switch(choice){
        case 0:
        return(
            <div>
                <button className="button-37" onClick={()=>{setChoice(1)}}>Back</button>
                <Edit_order name={data2}/>
            </div>
        )
        case 1:
        return(
            <div className="all_orders_container">
                <div className="orders_list">
                    <div className="order_items_container">
                    <div className="order_item_container">
                                <div className="item_span_order" onClick={(e)=>{sortInfo(e,7)}}>Status</div>
                                <div className="item_span_order" onClick={(e)=>{sortInfo(e,1)}}>Entry Date</div>
                                <div className="item_span_order" onClick={(e)=>{sortInfo(e,2)}}>Priority</div>
                                <div className="item_span_order" onClick={(e)=>{sortInfo(e,3)}}>Deadline</div>
                                <div className="item_span_order" onClick={(e)=>{sortInfo(e,4)}}>Days Left</div>
                                <div className="item_span_order" onClick={(e)=>{sortInfo(e,5)}}>Tracking ID</div>
                                <div className="item_span_order" onClick={(e)=>{sortInfo(e,6)}}>Description</div>
                        </div>
                    {
                            retrieve.map((item, id)=>{
                                return(
                                    <div key={id} className="order_item_container">

                                        <div className="item_span_order">{getSelect2(item.item.order_status)}</div>
                                        <div className="item_span_order">{item.item.entry_date} </div>
                                        <div className="item_span_order">{getSelect(item.item.priority)}</div>
                                        <div className="item_span_order">{item.item.dead_line}</div>
                                        <div className="item_span_order">{calculateDaysBetweenDates(formattedDate,item.item.dead_line)}</div>
                                        <div className="item_span_order">{item.item.tracking_id}</div>
                                        <div className="item_span_order">{item.item.description}</div>
                                    
                                        <div className="order_update_button_container">
                                            <button className="button-7" onClick={(e)=>{processData(e, [item._id, "0"], 2)}}>Cancelled</button>
                                            <button className="button-7" onClick={(e)=>{processData(e, [item._id, "1"], 2)}}>New</button>
                                            <button className="button-7" onClick={(e)=>{processData(e, [item._id, "2"], 2)}}>25%</button>
                                            <button className="button-7" onClick={(e)=>{processData(e, [item._id, "3"], 2)}}>50%</button>
                                            <button className="button-7" onClick={(e)=>{processData(e, [item._id, "4"], 2)}}>75%</button>
                                            <button className="button-7" onClick={(e)=>{processData(e, [item._id, "5"], 2)}}>Complete</button>
                                            <button className="button-7" onClick={(e)=>{setChoice(0); setData2(item._id)}}>Edit</button>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="order_info_container">
                    <div className="orders_totals">
                        <div>Totals order : {retrieve.length}</div>
                        <div>New Orders : {total[1]}</div>
                        <div>Orders @ 25% : {total[2]}</div>
                        <div>Orders @ 50% : {total[3]}</div>
                        <div>Orders @ 75% : {total[4]}</div>
                        <div>Orders Completed : {total[5]}</div>
                        <div>Orders Cancelled: {total[0]}</div>
                    </div>
                    <div className="orders_graphs">

                    <div className="error">
                        {errors}
                    </div>
                        <div className="graph_title">
                        Status / Priority
                        <div className="pie" style={{background: `conic-gradient(${graph2})`}}></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default All_orders