import React, {useState, useEffect} from 'react'
import axios from 'axios'
import create from "../sf/req/ce.js"
import deleteC from "../sf/req/de.js"
import edit from "../sf/req/dt.js"
import sortThis from "../sf/st.js"
import createGraph from '../sf/cg.js'
const link = process.env.REACT_APP_S
const Liabilities = () =>{
    const [data, setData] = useState([["Date Acquired", ""], ["Name", ""], ["Category", ""], ["Location", ""], ["Warranty", ""], ["Quantity", 0], ["Unit Value", 0], ["Make", ""], ["Model", ""], ["Serial Number", ""]])
    const [retrieve, setRetrieved] = useState([])
    const selection = ["Date Acquired","Name","Category","Location","Warranty","Quantity","Unit Value","Make","Model","Serial Number"]
    const [graph, setGraph] = useState("")
    const [graph2, setGraph2] = useState("")
    const [total,setTotals] = useState("")
    const [errors, setErrors] = useState("")
    const ads = process.env.REACT_APP_BUILD
    const setDataa = (e,t) =>{
        e.preventDefault()
        if(t[0].length > 25 || t[1].length > 25){
            setErrors(`${t[0]} cannot be greater then 25 Characters.`)
            return null
        }
        const copy = [...data]
        switch(t[0]){
            case selection[0]:
                copy[0] = t
            break;
            case selection[1]:
                copy[1] = t
            break;
            case selection[2]:
                copy[2] = t
            break;
            case selection[3]:
                copy[3] = t
            break;
            case selection[4]:
                copy[4] = t
            break;
            case selection[5]:
                if(!/^[0-9]+$/.test(t[1]) && t[1] !== ""){
                    setErrors(`${t[0]} must be a number`)
                    return null
                }
                setErrors("")
                copy[5] = t
            break;
            case selection[6]:
                if(!/^[0-9]+$/.test(t[1]) && t[1] !== ""){
                    setErrors(`${t[0]} must be a number`)
                    return null
                }
                setErrors("")
                copy[6] = t
            break;
            case selection[7]:
                copy[7] = t
            break;
            case selection[8]:
                copy[8] = t
            break;
            case selection[9]:
                copy[9] = t
            break;
            default:
                return false;
        }
        setData([...copy])
    }
    useEffect(() => {

        getData("",0,7)

    },[])
    const clear = (request) =>{
        setErrors(request)
        setData([["Date Acquired", ""], ["Name", ""], ["Category", ""], ["Location", ""], ["Warranty", ""], ["Quantity", 0], ["Unit Value", 0], ["Make", ""], ["Model", ""], ["Serial Number", ""]])
    }
    const [flip, setFlip] = useState(0)
    const sortInfo = async(e, choice) =>{
      e.preventDefault()
      if(choice === 1){
        //Name
        const copy = retrieve
        const sorted = await sortThis(copy, "name", `2${flip}`)
        setRetrieved(sorted)
      }
      if(choice === 2){
        //Category
        const copy = retrieve
        const sorted = await sortThis(copy, "category", `2${flip}`)
        setRetrieved(sorted)
      }
      if(choice === 3){
        //Location
        const copy = retrieve
        const sorted = await sortThis(copy, "location", `2${flip}`)
        setRetrieved(sorted)
      }
      if(choice === 4){
        //Quantity
        const copy = retrieve
        const sorted = await sortThis(copy, "qty", `1${flip}`)
        setRetrieved(sorted)
      }
      if(flip === 0){
        setFlip(1)
      }
      if(flip === 1){
        setFlip(0)
      }
    }
    const getData = async (content, choice, request) =>{
        try{
            const getAllLinks = [process.env.REACT_APP_VARIABLE_GL1,process.env.REACT_APP_VARIABLE_GL2,process.env.REACT_APP_VARIABLE_GL3,process.env.REACT_APP_VARIABLE_GL4,process.env.REACT_APP_VARIABLE_GL5,process.env.REACT_APP_VARIABLE_GL6,process.env.REACT_APP_VARIABLE_GL7,process.env.REACT_APP_VARIABLE_GL8,process.env.REACT_APP_VARIABLE_GL9,process.env.REACT_APP_VARIABLE_GL10,process.env.REACT_APP_VARIABLE_GL11,process.env.REACT_APP_VARIABLE_GL12,process.env.REACT_APP_VARIABLE_GL13,process.env.REACT_APP_VARIABLE_GL14]
            const getOneLinks = [process.env.REACT_APP_VARIABLE_GO1,process.env.REACT_APP_VARIABLE_GO2,process.env.REACT_APP_VARIABLE_GO3,process.env.REACT_APP_VARIABLE_GO4,process.env.REACT_APP_VARIABLE_GO5,process.env.REACT_APP_VARIABLE_GO6,process.env.REACT_APP_VARIABLE_GO7,process.env.REACT_APP_VARIABLE_GO8,process.env.REACT_APP_VARIABLE_GO9,process.env.REACT_APP_VARIABLE_GO10,process.env.REACT_APP_VARIABLE_GO11,process.env.REACT_APP_VARIABLE_GO12,process.env.REACT_APP_VARIABLE_GO13,process.env.REACT_APP_VARIABLE_GO14]
            const uc = [sessionStorage.getItem(process.env.REACT_APP_VARIABLE_B),sessionStorage.getItem(process.env.REACT_APP_VARIABLE_C)]
            switch(choice){
                case 0:
                    const res = await axios.post(`${link}${getAllLinks[request]}`,
                    {
                        k : process.env.REACT_APP_K,
                        'kk': uc[0],
                        'kk1': uc[1]
                    }) 
                    if(!res.data.request){
                        setErrors("Please Login or Create an Account!")
                        return null
                    }
                    setRetrieved(res.data.item)
                    setGraph(createGraph(res.data.item, "category", "qty"))
                    setGraph2(createGraph(res.data.item, "category", "unit_value"))
                    setTotals(totals(res.data.item))
                    setErrors("")
                    return null
                case 1: 
                    const res2 = await axios.post(`${link}${getOneLinks[request]}`,
                    {
                        k : process.env.REACT_APP_K,
                        content,
                        'kk': uc[0],
                        'kk1': uc[1]
                    })  
                            
                    if(!res2.data.request){
                        setErrors("Please Login or Create an Account!")
                        return null
                    }
                    setErrors("")
                    setData([["Date Acquired", res2.data.item.item.date_aquired], ["Name", res2.data.item.item.name], ["Category", res2.data.item.item.category], ["Location", res2.data.item.item.location], ["Warranty", res2.data.item.item.warranty], ["Quantity", res2.data.item.item.qty], ["Unit Value", res2.data.item.item.unit_value], ["Make", res2.data.item.item.make], ["Model", res2.data.item.item.model], ["Serial Number", res2.data.item.item.serial_number],["_id", res2.data.item._id]])
                    return null
                default:
                    return [false, false]  
            }
        }catch{
            return [false, "Something went wrong"]
        } 
    }
    const processData = async(e, content, choice) =>{
        e.preventDefault()
        const uc = [sessionStorage.getItem(process.env.REACT_APP_VARIABLE_B),sessionStorage.getItem(process.env.REACT_APP_VARIABLE_C)]
        switch (choice){
            case 0:
                const res0 = await create(uc, content, 5)
                if(res0){
                    setErrors("Created")
                    clear("Created")
                    getData("", 0,7)
                    return null
                }else{
                    setErrors("Error creating")
                    return null
                }
                break;
            case 1:
                const res1 = await deleteC(uc, content, 5)
                if(res1){
                    setErrors("Removed")
                    clear("Removed")
                    getData("", 0, 7)
                    return null
                }else{
                    setErrors("Error removing")
                    return null
                }
                break;
            case 2:
                const res2 = await edit(uc, content, 8)
                if(res2){
                    setErrors("Updated")
                    clear("Updated")
                    getData("", 0,7)
                    return null
                }else{
                    setErrors("Error updating")
                    return null
                }
                break;
            default: 
                return false
        }
    }
    const totals = (retrieve) =>{
        var total1 = 0
        var total2 = 0
            for(let i =0; i < retrieve.length; i++){
                total1 = total1 + (parseInt(retrieve[i].item.unit_value) * parseInt(retrieve[i].item.qty))
                total2 = total2 + parseInt(retrieve[i].item.qty)
            }
            return [total1,total2]
    }
    const c = sessionStorage.getItem("key")
    return(
        <div className="accounting_container">
            <div className="accounting_list">
                <div className="item_container">
                        <div className="item_span" onClick={(e)=>{sortInfo(e,1)}}>Name</div>
                        <div className="item_span" onClick={(e)=>{sortInfo(e,2)}}>Category</div>
                        <div className="item_span" onClick={(e)=>{sortInfo(e,3)}}>Location</div>
                        <div className="item_span" onClick={(e)=>{sortInfo(e,4)}}>Quantity</div>
                </div>
                {
                    retrieve.map((item, id)=>{
                        return (
                            <div key={id} className="item_container">
                                <div className="item_span">{item.item.name} </div>
                                <div className="item_span">{item.item.category}</div>
                                <div className="item_span">{item.item.location}</div>
                                <div className="item_span">{item.item.qty}</div>
                            
                                <button className="button-2" onClick={(e)=>{getData(item._id, 1, 5)}}>Edit</button>
                                <button className="button-2" onClick={(e)=>{processData(e, item._id, 1)}}>Remove</button>
                            </div>
                        )
                    })
                }
            </div>
            <div className="accounting_form">
                <div className="accounting_uin">
                    <div className="accounting_label">
                        {data[0][0]} 
                    </div>
                    <input type="date" className="accounting_input" onChange={(e)=>{setDataa(e,[selection[0] , e.target.value])}} value={data[0][1]}/>
                </div>     
                <div className="accounting_uin">
                    <div className="accounting_label">
                        {data[1][0]} 
                    </div>
                    <input type="text" className="accounting_input" onChange={(e)=>{setDataa(e,[selection[1] , e.target.value])}} value={data[1][1]}/>
                </div>   
                <div className="accounting_uin">
                    <div className="accounting_label">
                        {data[2][0]} 
                    </div>
                    <input type="text" className="accounting_input" onChange={(e)=>{setDataa(e,[selection[2] , e.target.value])}} value={data[2][1]}/>
                </div>   
                <div className="accounting_uin">
                    <div className="accounting_label">
                        {data[3][0]} 
                    </div>
                    <input type="text" className="accounting_input" onChange={(e)=>{setDataa(e,[selection[3] , e.target.value])}} value={data[3][1]}/>
                </div>   
                <div className="accounting_uin">
                    <div className="accounting_label">
                        {data[4][0]} 
                    </div>
                    <input type="text" className="accounting_input" onChange={(e)=>{setDataa(e,[selection[4] , e.target.value])}} value={data[4][1]}/>
                </div>   
                <div className="accounting_uin">
                    <div className="accounting_label">
                        {data[5][0]} 
                    </div>
                    <input type="text" className="accounting_input" onChange={(e)=>{setDataa(e,[selection[5] , e.target.value])}} value={data[5][1]}/>
                </div>   
                <div className="accounting_uin">
                    <div className="accounting_label">
                        {data[6][0]} 
                    </div>
                    <input type="text" className="accounting_input" onChange={(e)=>{setDataa(e,[selection[6] , e.target.value])}} value={data[6][1]}/>
                </div>   
                <div className="accounting_uin">
                    <div className="accounting_label">
                        {data[7][0]}
                    </div>
                    <input type="text" className="accounting_input" onChange={(e)=>{setDataa(e,[selection[7] , e.target.value])}} value={data[7][1]}/>
                </div>   
                <div className="accounting_uin">
                    <div className="accounting_label">
                        {data[8][0]} 
                    </div>
                    <input type="text" className="accounting_input" onChange={(e)=>{setDataa(e,[selection[8] , e.target.value])}} value={data[8][1]}/>
                </div>   
                <div className="accounting_uin">
                    <div className="accounting_label">
                        {data[9][0]} 
                    </div>
                    <input type="text" className="accounting_input" onChange={(e)=>{setDataa(e,[selection[9] , e.target.value])}} value={data[9][1]}/>
                </div>    
                {
                    c?
                    <div>
                        <button className="button-7" onClick={(e)=>{processData(e,data, 0)}}>Create</button>   
                        <button className="button-7" onClick={(e)=>{clear("")}}>Clear</button>  
                        <button className="button-7" onClick={(e)=>{processData(e,data, 2)}}>Update</button> 
                    
                    </div>
                    : <div></div>
                }
                <div>
                    <div className="error">
                        {errors}
                    </div>
                </div>
            </div>
            <div className="accounting_totals">
                <div>Total Value : ${total[0]}</div>
                <div>Total Items : {total[1]}</div>
            </div>
            <div className="accounting_graphs">
                <div className="graph_title">
                    Category/Quantity
                    <div className="pie" style={{background: `conic-gradient(${graph})`}}></div>
                </div>
                <div className="graph_title">
                    Category/Unit Value
                    <div className="pie" style={{background: `conic-gradient(${graph2})`}}></div>
                </div>
            </div>
                    {
                        ads?
                        <div><script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3199046355212507"
                        crossorigin="anonymous"></script></div>
                        : <div></div>
                    }
        </div>
    )
}
export default Liabilities