import {Link} from "react-router-dom";
const Nav = () =>{

    return (
        <div>
            <div className="home_buttons">
                <Link className="button-32" to={'/Features'}>Features</Link>
                <Link className="button-32" to={'/Instructions'}>How to</Link>
                <Link className="button-32" to={'/Updates'}>Monthly Updates</Link>
                <Link className="button-32" to={'/'}>Login</Link>
             </div>
        </div>
    )
}

export default Nav