const sortThis = async(data, variable, request) =>{

    const sortNumberAscending = (arrayIn) =>{

        arrayIn.sort((a, b) => {
            return a["item"][variable] - b["item"][variable];
        });
        
        return arrayIn
    }
    
    const sortNumberDescending = (arrayIn) =>{
        arrayIn.sort((a, b) => {
            return b["item"][variable] - a["item"][variable];
        });
    
        return arrayIn
    }
    
    const sortDateAscending = (arrayIn) =>{
        arrayIn.sort((a, b) => {
            var someDate1 = new Date(a["item"][variable]);
            var someDate2 = new Date(b["item"][variable]);
            someDate1 = someDate1.getTime();
            someDate2 = someDate2.getTime();
            return someDate1- someDate2;
        });
    
        return arrayIn
    }
    
    const sortDateDescending = (arrayIn) =>{
        arrayIn.sort((a, b) => {
            var someDate1 = new Date(a["item"][variable]);
            var someDate2 = new Date(b["item"][variable]);
            someDate1 = someDate1.getTime();
            someDate2 = someDate2.getTime();
            return someDate2 - someDate1;
        });
    
        return arrayIn
    }

    const sortAlphabeticallyAscending = (arrayIn) =>{
            //
        
            arrayIn.sort((a, b) => {
                let fa = a["item"][variable].toLowerCase(),
                    fb = b["item"][variable].toLowerCase();
            
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });

        return arrayIn
    }
    
    const sortAlphabeticallyDescending = (arrayIn) =>{
        //
        arrayIn.sort((a, b) => {
            let fa = a["item"][variable].toLowerCase(),
                fb = b["item"][variable].toLowerCase();
        
            if (fa > fb) {
                return -1;
            }
            if (fa < fb) {
                return 1;
            }
            return arrayIn;
        });
        
        return arrayIn
    }
    /*
    1 Number
    2 Alpha
    3 Date
    */
    if(request === '10'){
       const info = await sortNumberAscending(data)
       return info
    }    
    if(request === '11'){
       const info = await sortNumberDescending(data)
       return info 
    }
    if(request === '20'){
       const info = await sortAlphabeticallyAscending(data)
       return info
    }
    if(request === '21'){
       const info = await sortAlphabeticallyDescending(data)
       return info
    }
    if(request === '30'){
       const info = await sortDateAscending(data)
       return info
    }
    if(request === '31'){
       const info = await sortDateDescending(data)
       return info
    }
}

export default sortThis