import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Gallery from './engine/gallery.js'
import './mip.css'
const link = process.env.S2
const Infopromo = () =>{

    /*
        Get all images
        Get one image
        Get web summary
        Create image
        Update image
        Update delete image
    */

    //Convert image to Base64

    const [errors, setErrors] = useState([])
    const [images, setImages] = useState([])
    const [name, setName] = useState("")
    const [description, setDesc] = useState("")
    const [webId, setWebId] = useState("")

    const [image, setImg] = useState("no file loaded")
    // Img to Base64
    function encodeImageFileAsURL(element) {
        //Get file date
        let file = element.files[0];
        //Create reader
        let reader = new FileReader();
        //Reader File data
        reader.onloadend = function() {
        //Print File data
        //console.log(reader.result)
        //Save File Data
        setImg(reader.result)
        }
        //intiate read
        reader.readAsDataURL(file);
        console.log("Read")
    }

    const upload_img = async(e) =>{

        e.preventDefault()
       /* 
       const uc = [sessionStorage.getItem(process.env.REACT_APP_VARIABLE_B),sessionStorage.getItem(process.env.REACT_APP_VARIABLE_C)]
       ,
                k : process.env.REACT_APP_K,
                'kk': uc[0],
                'kk1': uc[1]
       */
        try{
            const uc = [sessionStorage.getItem(process.env.REACT_APP_VARIABLE_B),sessionStorage.getItem(process.env.REACT_APP_VARIABLE_C)]
            
            const res = await axios.post(`http://localhost:5443/i/1`,
            {  
                content : {
                    image,
                    name,
                    description
                }, 
                    k : "potato762",
                    'kk': uc[0],
                    'kk1': uc[1],
                    k4 : "spruce",
                    k5 : "test"
            })
            if(!res.data.request){
                return false
            }
            return res.data.item
        }catch(err){
            return false
        }
    }

    const clear_img = (e) =>{
        e.preventDefault()
        setImg("No file loaded.")
    }

    const remove_img = (e) =>{
        e.preventDefault()

    }
    const getData = async (content, choice, request) => {
        /*
        Get all
        Get one
        */
            try{
                const getAllLinks = []
                const getOneLinks = []
                const uc = [sessionStorage.getItem(process.env.REACT_APP_VARIABLE_B),sessionStorage.getItem(process.env.REACT_APP_VARIABLE_C)]
                switch(choice){
                    case 0:
                        const res = await axios.post(`${link}${getAllLinks[request]}`,
                        {
                            k : process.env.REACT_APP_K,
                            'kk': uc[0],
                            'kk1': uc[1]
                        })  
                        if(!res.data.request){
                            setErrors("Please Login or Create an Account!")
                            return null
                        }
                        setImages(res.data.item)
                        return null
                    case 1: 
                        const res2 = await axios.post(`${link}${getOneLinks[request]}`,
                        {
                            k : process.env.REACT_APP_K,
                            content,
                            'kk': uc[0],
                            'kk1': uc[1]
                        })  
                        if(!res2.data.request){
                            setErrors("Please Login or Create an Account!")
                            return null
                        }
                        setImages(res2.data)
                        return null
                    default:
                        return [false, false]  
                }
            }catch{
                return [false, "Something went wrong"]
            } 
        }

        useEffect(()=>{

            getData("",0,0)

        },[])

    return(
        <div className="form_mip">
            <br />
            <div className="mip_uin">
                <label className="mip_label" htmlFor="">Web summary</label>
                <textarea name="" id="" cols="25" rows="10" className="w_sum"></textarea>
                <button className="button-7">Load</button>
                <button className="button-7">Update</button>
            </div>

            <div className="image_temp_container">
                
                <img className="gallery_img" src={image} alt="Sorry Image not found" />
                <input type="file" onChange={(e)=>{encodeImageFileAsURL(e.target)}} />
    
                <div>
                    {errors}
                </div>
                <div>
                    <label htmlFor="">Name</label>
                    <input type="text" onChange={(e)=>{setName(e.target.value)}} value={name}/>
                </div>
                <div>
                    <label htmlFor="">Description</label>
                    <input type="text"  onChange={(e)=>{setDesc(e.target.value)}} value={description}/>
                </div>
                <div>
                    <label htmlFor="">Web Id</label>
                    <input type="text"  onChange={(e)=>{setWebId(e.target.value)}} value={webId}/>
                </div>

                <div>
                    <button className="" onClick={(e)=>{upload_img(e)}}> Upload Image </button>
                    <button className="" onClick={(e)=>{clear_img(e)}}> Clear Image </button>
                    <button className="" > Remove Image </button>
                </div>
            </div>
            <Gallery/>
        </div>
    )
}
export default Infopromo