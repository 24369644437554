import React, {useState, useEffect} from 'react'
import axios from 'axios'
import sortThis from "../sf/st.js"
import create from "../sf/req/ce.js"
import deleteC from "../sf/req/de.js"
import edit from "../sf/req/dt.js"
const link = process.env.REACT_APP_S
const Manage_contacts = () =>{
    const [data, setData] = useState([["First Name",""],["Last Name",""],["Company Name",""],["E-mail",""],["Phone",""],["Address",""],["Preferences",""],["Professional Summary",""],["Langugaes Spoken",""]])
    const selection = ["First Name","Last Name","Company Name","E-mail","Phone","Address","Preferences","Professional Summary","Languages Spoken"]
    const [retrieve, setRetrieved] = useState([])
    const [errors, setErrors] = useState("")

     const clear = (e,request) =>{
        e.preventDefault()
         setErrors(request)
         setData([["First Name",""],["Last Name",""],["Company Name",""],["E-mail",""],["Phone",""],["Address",""],["Preferences",""],["Professional Summary",""],["Langugaes Spoken",""]])
     }
     const [flip, setFlip] = useState(0)
     const sortInfo = async(e, choice) =>{
       e.preventDefault()
       if(choice === 1){
         //AssetId
         const copy = retrieve
         const sorted = await sortThis(copy, "asset_id", `2${flip}`)
 
         setRetrieved(sorted)
       }
       if(choice === 2){
         //Name
         const copy = retrieve
         const sorted = await sortThis(copy, "name", `2${flip}`)
         setRetrieved(sorted)
       }
       if(choice === 3){
         //Category
         const copy = retrieve
         const sorted = await sortThis(copy, "category", `2${flip}`)
         setRetrieved(sorted)
       }
       if(choice === 4){
         //Quantity
         const copy = retrieve
         const sorted = await sortThis(copy, "qty", `1${flip}`)
         setRetrieved(sorted)
       }
       if(flip === 0){
         setFlip(1)
       }
       if(flip === 1){
         setFlip(0)
       } 
     }
    const setDataa = (e,t) =>{
        e.preventDefault()
        if(t[0].length > 25 || t[1].length > 25){
            setErrors(`${t[0]} cannot be greater then 25 Characters.`)
            return null
        }
        const copy = [...data]
        switch(t[0]){
            case selection[0]:
                copy[0] = t
            break;
            case selection[1]:
                copy[1] = t
            break;
            case selection[2]:
                copy[2] = t
            break;
            case selection[3]:
                copy[3] = t
            break;
            case selection[4]:
                copy[4] = t
            break;
            case selection[5]:
                copy[5] = t
            break;
            case selection[6]:
                copy[6] = t
            break;
            case selection[7]:
                copy[7] = t
            break;
            case selection[8]:
                copy[8] = t
            break;
            default:
                return false;
        }
        setErrors("")
        setData([...copy])
    }
    useEffect(()=>{

        getData("", 0, 0)

    }, [])
    const getData = async (content, choice, request) =>{
        try{
            const getAllLinks = ["/contact/all"]
            const getOneLinks = ["/contact/one"]
            const uc = [sessionStorage.getItem(process.env.REACT_APP_VARIABLE_B),sessionStorage.getItem(process.env.REACT_APP_VARIABLE_C)]
            switch(choice){
                case 0:
                    const res = await axios.post(`${link}${getAllLinks[request]}`,
                    {
                        k : process.env.REACT_APP_K,
                        'kk': uc[0],
                        'kk1': uc[1]
                    })       
                    if(!res.data.request){
                        setErrors("")
                        return false
                    }
                    setRetrieved(res.data.item)
                    return null
                case 1: 
                    const res2 = await axios.post(`${link}${getOneLinks[request]}`,
                    {
                        k : process.env.REACT_APP_K,
                        content,
                        'kk': uc[0],
                        'kk1': uc[1]
                    })     
                    if(!res2.data.request){
                        setErrors("Error retrieving item")
                        return false
                    }
                    setData([   
                                ["First Name",res2.data.item.item.FirstN],
                                ["Last Name",res2.data.item.item.LastN],
                                ["Company Name",res2.data.item.item.CompanyN],
                                ["E-mail",res2.data.item.item.Email],
                                ["Phone",res2.data.item.item.Phone],
                                ["Address",res2.data.item.item.Address],
                                ["Preferences",res2.data.item.item.Pref],
                                ["Professional Summary",res2.data.item.item.ProS],
                                ["Langugaes Spoken",res2.data.item.item.Languages],
                                ["_id", res2.data.item._id]
                            ])
                    return null
                default:
                    return false 
            }
        }catch{
            return [false, "Something went wrong"]
        } 
    }
    const processData = async(e, content, choice) =>{
        e.preventDefault()
        const uc = [sessionStorage.getItem(process.env.REACT_APP_VARIABLE_B),sessionStorage.getItem(process.env.REACT_APP_VARIABLE_C)]
        switch (choice){
            case 0:
                const res0 = await create(uc, content, 14)
                if(res0){
                    setErrors("Created")
                    clear(e,"Created")
                    getData(null, 0,0)
                    return null
                }else{
                    setErrors("Error creating")
                    return null
                }
                break;
            case 1:
                const res1 = await deleteC(uc, content, 11)
                if(res1){
                    setErrors("Removed")
                    clear(e,"Removed")
                    getData(null, 0, 0)
                    return null
                }else{
                    console.log("Error")
                    setErrors("Error removing")
                }
                break;
            case 2:
                const res2 = await edit(uc, content, 18)
                if(res2){
                    setErrors("Updated")
                    clear(e,"Updated")
                    getData(null, 0,0)
                    return null
                }else{
                    setErrors("Error updating")
                }
                break;
            default: 
                return false
        }
    }
    const c = sessionStorage.getItem("key")
     return(
         <div className="whole_container">
             <div className="contact_container">
                 <div className="contact_list">
                     <div className="item_container">
                             <div className="item_span" onClick={(e)=>{sortInfo(e,1)}}>Last Name</div>
                             <div className="item_span" onClick={(e)=>{sortInfo(e,2)}}>E-mail</div>
                             <div className="item_span" onClick={(e)=>{sortInfo(e,3)}}>Phone</div>
                             <div className="item_span" onClick={(e)=>{sortInfo(e,4)}}>Company Name</div>
                     </div>
                     {
                         retrieve.map((item, id)=>{
                             return (
                                 <div key={id} className="item_container">
                                     <div className="item_span">{item.item.LastN} </div>
                                     <div className="item_span">{item.item.Email}</div>
                                     <div className="item_span">{item.item.Phone}</div>
                                     <div className="item_span">{item.item.CompanyN}</div>
                                 
                                     <button className="button-2" onClick={(e)=>{getData(item._id, 1, 0)}}>Edit</button>
                                     <button className="button-2" onClick={(e)=>{processData(e, item._id, 1)}}>Remove</button>
                                 </div>
                             )
                         })
                     }
                 </div>
                 <div className="contact_form">
                     <div className="contact_uin">
                         <div className="contact_label">
                             <label htmlFor="">First Name</label>
                         </div>
                         <div className="contact_input">
                             <input type="text" className="accounting_input" onChange={(e)=>{setDataa(e,[selection[0] , e.target.value])}} value={data[0][1]}/>
                         </div>
                     </div>
                     <div className="contact_uin">
                         <div className="contact_label">
                             <label htmlFor="">Last Name</label>
                         </div>
                         <div className="contact_input">
                             <input type="text" className="accounting_input" onChange={(e)=>{setDataa(e,[selection[1] , e.target.value])}} value={data[1][1]}/>  
                         </div>
                     </div>
                     <div className="contact_uin">
                         <div className="contact_label">
                             <label htmlFor="">Company Name</label>
                         </div>
                         <div className="contact_input">
                             <input type="text" className="accounting_input" onChange={(e)=>{setDataa(e,[selection[2] , e.target.value])}} value={data[2][1]}/>  
                         </div>
                     </div>
                     <div className="contact_uin">
                         <div className="contact_label">
                             <label htmlFor="">E-mail</label>
                         </div>
                         <div className="contact_input">
                             <input type="text" className="accounting_input" onChange={(e)=>{setDataa(e,[selection[3] , e.target.value])}} value={data[3][1]}/>  
                         </div>
                     </div>
                     <div className="contact_uin">
                         <div className="contact_label">
                             <label htmlFor="">Phone</label>
                         </div>
                         <div className="contact_input">
                             <input type="text" className="accounting_input" onChange={(e)=>{setDataa(e,[selection[4] , e.target.value])}} value={data[4][1]}/>  
                         </div>
                     </div>
                     <div className="contact_uin">
                         <div className="contact_label">
                             <label htmlFor="">Address</label>
                         </div>
                         <div className="contact_input">
                             <input type="text" className="accounting_input" onChange={(e)=>{setDataa(e,[selection[5] , e.target.value])}} value={data[5][1]}/>  
                         </div>
                     </div>
                     <div className="contact_uin">
                         <div className="contact_label">
                             <label htmlFor="">Preferences</label>
                         </div>
                         <div className="contact_input">
                             <textarea name="" id="" cols="40" rows="5" onChange={(e)=>{setDataa(e,[selection[6] , e.target.value])}} value={data[6][1]}></textarea> 
                         </div>
                     </div>
                     <div className="contact_uin">
                         <div className="contact_label">
                             <label htmlFor="">Professional Summary</label>
                         </div>
                         <div className="contact_input">
                             <textarea name="" id="" cols="40" rows="5" onChange={(e)=>{setDataa(e,[selection[7] , e.target.value])}} value={data[7][1]}></textarea>
                         </div>
                     </div>
                     <div className="contact_uin">
                         <div className="contact_label">
                             <label htmlFor="">Langugages Spoken</label>
                         </div>
                         <div className="contact_input"> 
                             <textarea name="" id="" cols="40" rows="5" onChange={(e)=>{setDataa(e,[selection[8] , e.target.value])}} value={data[8][1]}></textarea>
                         </div>
                     </div>
                    {
                        c?
                        <div>
                             <button className="button-7" onClick={(e)=>{processData(e, data, 2)}}>Update</button>
                            <button className="button-7" onClick={()=>{clear("")}}>Clear</button>
                            <button className="button-7" onClick={(e)=>{processData(e,data,0)}}>Create</button>
                        </div>
                        : <div></div>
                    }
                    <div className="error">
                        {errors}
                    </div>
                 </div>
             </div>
         </div>
     )
 }
export default Manage_contacts