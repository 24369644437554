import axios from 'axios'
const link = process.env.REACT_APP_S
const cLinks = [process.env.REACT_APP_VARIABLE_CL1,process.env.REACT_APP_VARIABLE_CL2,process.env.REACT_APP_VARIABLE_CL3,process.env.REACT_APP_VARIABLE_CL4,process.env.REACT_APP_VARIABLE_CL5,process.env.REACT_APP_VARIABLE_CL6,process.env.REACT_APP_VARIABLE_CL7,process.env.REACT_APP_VARIABLE_CL8,process.env.REACT_APP_VARIABLE_CL9,process.env.REACT_APP_VARIABLE_CL10,process.env.REACT_APP_VARIABLE_CL11,process.env.REACT_APP_VARIABLE_CL12, "/recruitment/create", "/employee/create","/contact/create","/lead/create"]
const create = async(uc, content, request) =>{
    try{
        const res = await axios.post(`${link}${cLinks[request]}`,
        {   
            k : process.env.REACT_APP_K,
            content,
            'kk': uc[0],
            'kk1': uc[1]
        })
        if(!res.data.request){
            return false
        }
        return res.data.item
    }catch(err){
        return false
    }
}
export default create