import axios from 'axios'
const link = process.env.REACT_APP_S
const uL = [process.env.REACT_APP_VARIABLE_UL1,process.env.REACT_APP_VARIABLE_UL2,process.env.REACT_APP_VARIABLE_UL3,process.env.REACT_APP_VARIABLE_UL4,process.env.REACT_APP_VARIABLE_UL5,process.env.REACT_APP_VARIABLE_UL6,process.env.REACT_APP_VARIABLE_U7,process.env.REACT_APP_VARIABLE_UL8,process.env.REACT_APP_VARIABLE_UL9,process.env.REACT_APP_VARIABLE_UL10,process.env.REACT_APP_VARIABLE_UL11,process.env.REACT_APP_VARIABLE_UL12,process.env.REACT_APP_VARIABLE_UL13,process.env.REACT_APP_VARIABLE_UL14,process.env.REACT_APP_VARIABLE_UL15,process.env.REACT_APP_VARIABLE_UL16,"/recruitment/update","/employee/update","/contact/update","/lead/update"]
const edit = async(uc, content, request) =>{
    try{
        const res = await axios.put(`${link}${uL[request]}`,{
            k : process.env.REACT_APP_K,
            content,
            'kk': uc[0],
            'kk1': uc[1]
        })
        if(!res.data.request){
            return false
        }
        return true
    }catch{
        return false
    }
}
export default edit