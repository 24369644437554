import React, {useState,useEffect} from 'react'
import axios from 'axios'
import './budget.css'
import create from "../sf/req/ce.js"
const link = process.env.REACT_APP_S
const Create_budget = () =>{
    const months = ["January","Februrary","March","April","May","June","July","August","September","October","November","December"]
    const [data, setData] = useState([["Month",""],["Year",""],["Budget Name",""],["Project income",""]])
    const [retrieve, setRetrieved] = useState([])
    const selection = ["Month","Year","Budget Name","Project income", "Expenses"]
    const [expensesList, setExpensesList] = useState([])
    const [expense, setExpense] = useState("")
    const [price, setPrice] = useState("")
    const [total, setTotal] = useState(0)
    const [errors, setErrors] = useState("")
    useEffect(()=>{

        processData("", data[0][1], 1)
        processData("", data[1][1], 2)
        
    },[])   
    const clear = (request) =>{
        setErrors(request)
        setData([["Month",""],["Year",""],["Budget Name",""],["Project income",""]])
        setExpensesList([])
    }
    const addToExpenses = (e) =>{
        e.preventDefault()
        console.log(expense, price)
        if(expense === ""){
            setErrors("Expense cannot be empty")
            return null
        }
        const copy = [...expensesList]
        if(!/^[0-9]+$/.test(price)){
            setErrors(`Price must be a number`)
            return null
        }
        copy.push({"expense" : expense, "price" : parseInt(price)})
        setExpense("")
        setPrice("")
        setErrors("")
        setExpensesList(copy)
        projectedExpenses()
    }
    const removeFromExpenses = (e, id) =>{
        e.preventDefault()
        const copy = [...expensesList]
        copy.splice(id, 1);
        setExpensesList(copy)
        projectedExpenses()
    }
    const processData = async(e, content, choice) =>{
        //e.preventDefault()
        const uc = [sessionStorage.getItem(process.env.REACT_APP_VARIABLE_B),sessionStorage.getItem(process.env.REACT_APP_VARIABLE_C)]
        switch (choice){
            case 0:
                const res0 = await create(uc, content, 12)
                if(res0){
                    setErrors("Created")
                    clear(e,"Created")
                    processData(null, 0,0)
                    return null
                }else{
                    setErrors("Error creating")
                    return null
                }
                break;
            case 1:
                var getAllLinks = ["/budget/expenses"]
                    var res = await axios.post(`${link}${getAllLinks[0]}`,
                    {
                        k : process.env.REACT_APP_K,
                        content,
                        'kk': uc[0],
                        'kk1': uc[1]
                    })       
                    if(!res.data.request){
                        setErrors("")
                        return false
                    }
                    setRetrieved(res.data.item)
                    var total = 0
            
                    return total
            case 2:
                var getAllLinks = ["/budget/earnings"]
                    var res = await axios.post(`${link}${getAllLinks[0]}`,
                    {
                        k : process.env.REACT_APP_K,
                        content,
                        'kk': uc[0],
                        'kk1': uc[1]
                    })       
                    if(!res.data.request){
                        setErrors("")
                        return false
                    }
                    setRetrieved(res.data.item)
                    var total = 0
            
                    return total
            default: 
                return false
        }
    }
    const projectedExpenses = () =>{
        var total = 0
        expensesList.map((item)=>{
            total = parseInt(item.price) + total
        })
        setTotal(total)
    }
    const createBudget = (e) =>{
        e.preventDefault()
    }
    const setDataa = (e,t) =>{
        e.preventDefault()
        console.log(t)
        if(t[0].length > 25 ||t[1].length > 25){
            setErrors(`${t[0]} cannot be greater then 25 Characters.`)
            return null
        }
        const copy = [...data]
        switch(t[0]){
            case selection[0]:
                copy[0] = t
            break;
            case selection[1]:
                copy[1] = t
            break;
            case selection[2]:
                copy[2] = t
            break;
            case selection[3]:
                if(!/^[0-9]+$/.test(t[1]) && t[1] !== ""){
                    setErrors(`${t[0]} must be a number`)
                    return null
                }
                copy[3] = t
            break;
            default:
                return false;
        }
        setErrors("")
        setData([...copy])
    }
    const c = sessionStorage.getItem("key")
    return(
        <div className="whole_container">
            <div className="budget_create_container">
            {
                c?
                <div>
                    <button className="button-7" onClick={()=>{clear("")}}>Clear</button>
                    <button className="button-7" onClick={(e)=>{createBudget(e)}}>Create</button>
                </div>
                : <div></div>
            }
            <div className="error">
                {errors}
            </div>
                <div className="budget_create_input_container">
                
                    <div className="budget_create_uin2">
                        <div className="budget_create_label2">
                            Budget Name : 
                        </div>
                        <div className="budget_create_input2">
                            <input type="text" onChange={(e)=>{setDataa(e,[selection[2] , e.target.value])}} value={data[2][1]}/>
                        </div>
                    </div>

                    <div className="budget_create_uin2">
                        <div className="budget_create_label2">
                            Month
                        </div>
                        <div className="budget_create_input2">
                            <select name="" id="" onChange={(e)=>{setDataa(e,[selection[0] , e.target.value])}} value={data[0][1]}>
                                {months.map((item, id)=>{
                                    return(
                                        <option value={id} key={id}>{item}</option> 
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="budget_create_uin2">
                        <div className="budget_create_label2">
                            Year
                        </div>
                        <div className="budget_create_input2">
                            <select name="" id="" onChange={(e)=>{setDataa(e,[selection[1] , e.target.value])}} value={data[1][1]}>
                                <option value="">2023</option>
                                <option value="">2023</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="actual_earnings">
                    <div className="budget_create_label">Actual Earnings : $4,500</div> 
                </div>
                <div className="actual_expenses">           
                    <div className="budget_create_label">Actual Expenses : $2000</div> 
                </div>
                <div className="projected_income">
                    <div className="budget_create_label">Projected Earnings : </div> 
                    <div className="budget_create_input">
                        <input type="text"  onChange={(e)=>{setDataa(e,[selection[3] , e.target.value])}} value={data[3][1]}/>
                    </div>
                </div>
                <div className="projected_income">
                    <div className="budget_create_label">Projected Expenses : </div> 
                    <div className="budget_create_input">
                        <label htmlFor="">${total}</label>
                    </div>
                </div>
                <div className="expenses_to_pay">
                    <div className="budget_create_label">
                        Expenses To Pay
                    </div>
                    <div className="budget_create_uin">
                        <div className="budget_create_label">Expense : </div> 
                        <div className="budget_create_input">
                            <input type="text" onChange={(e)=>{setExpense(e.target.value)}} value={expense}/>
                        </div> 
                    </div>
                    <div className="budget_create_uin">
                        <div className="budget_create_label">Price : $</div> 
                        <div className="budget_create_input">
                            <input type="text" onChange={(e)=>{setPrice(e.target.value)}} value={price}/>
                        </div> 
                    </div>
                    <button className="button-7" onClick={(e)=>{addToExpenses(e)}}>Add</button>   
                    <div className="error">{errors}</div> 
                    <div className="expenses_list">
                        <div className="item_container">
                            <div className="item_span">Expense</div>  
                            <div className="item_span">Price</div>
                            <div className="item_span"></div>
                        </div>
                        {expensesList.map((item, id)=>{
                            return(
                                <div key={id} className="item_container">
                                <div className="item_span">{item.expense} </div>
                                <div className="item_span">${item.price}</div>
                                    <button className="button-2" onClick={(e)=>{removeFromExpenses(e,id)}}>Remove</button>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Create_budget