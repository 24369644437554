import React, {useState} from 'react'
import create from "../sf/req/ce.js"
const New_order = () =>{
    const [data, setData] = useState([["Entry Date",""],["Priority", 0],["Dead Line",""],["Days Left",0],["Tracking ID",""],["Estimated Time",0],["Actual Time",0],["Billed Time",0],["Description",""],["Items",""],["Assigned to",""],["Comments",""],["Customer Name",""],["Customer Phone",""],["Customer E-mail",""],["Customer Address Line",""],["Customer State",""],["Customer City",""],["Customer Zip",""],["Order Status",""]])
    const selection = ["Entry Date","Priority","Dead Line","Days Left","Tracking ID","Estimated Time","Actual Time","Billed Time","Description","Items","Assigned to","Comments","Customer Name","Customer Phone","Customer E-mail","Customer Address Line","Customer State","Customer City","Customer Zip","Order Status"]
    const [errors, setErrors] = useState("")
    const clear = (request) =>{
        setErrors(request)
        setData([["Entry Date",""],["Priority", 0],["Dead Line",""],["Days Left",0],["Tracking ID",""],["Estimated Time",0],["Actual Time",0],["Billed Time",0],["Description",""],["Items",""],["Assigned to",""],["Comments",""],["Customer Name",""],["Customer Phone",""],["Customer E-mail",""],["Customer Address Line",""],["Customer State",""],["Customer City",""],["Customer Zip",""],["Order Status",""]])
        } 
   const setDataa = (e,t) =>{
    e.preventDefault()
    if(t[0].length > 25 || t[1].length > 25){
        setErrors(`${t[0]} cannot be greater then 25 Characters.`)
        return null
    }
    const copy = [...data]
    switch(t[0]){
        case selection[0]:
            copy[0] = t
        break;
        case selection[1]:
            copy[1] = t
        break;
        case selection[2]:
            copy[2] = t
        break;
        case selection[3]:
            if(!/^[0-9]+$/.test(t[1]) && t[1] !== ""){
                setErrors(`${t[0]} must be a number`)
                return null
            }
            setErrors("")
            copy[3] = t
        break;
        case selection[4]:
            copy[4] = t
        break;
        case selection[5]:
            if(!/^[0-9]+$/.test(t[1]) && t[1] !== ""){
                setErrors(`${t[0]} must be a number`)
                return null
            }
            setErrors("")
            copy[5] = t
        break;
        case selection[6]:
            if(!/^[0-9]+$/.test(t[1]) && t[1] !== ""){
                setErrors(`${t[0]} must be a number`)
                return null
            }
            setErrors("")
            copy[6] = t
        break;
        case selection[7]:
            if(!/^[0-9]+$/.test(t[1]) && t[1] !== ""){
                setErrors(`${t[0]} must be a number`)
                return null
            }
            setErrors("")
            copy[7] = t
        break;
        case selection[8]:
            copy[8] = t
        break;
        case selection[9]:
            copy[9] = t
        break;
        case selection[10]:
            copy[10] = t
        break;
        case selection[11]:
            copy[11] = t
        break;
        case selection[12]:
            copy[12] = t
        break;
        case selection[13]:
            copy[13] = t
        break;
        case selection[14]:
            copy[14] = t
        break;
        case selection[15]:
            copy[15] = t
        break;
        case selection[16]:
            copy[16] = t
        break;
        case selection[17]:
            copy[17] = t
        break;
        case selection[18]:
            copy[18] = t
        break;
        case selection[19]:
            copy[19] = t
        break;
        default:
            return false;
    }
    setData([...copy])
    }
    const processData = async(e, content, choice) =>{
        e.preventDefault()
        const uc = [sessionStorage.getItem(process.env.REACT_APP_VARIABLE_B),sessionStorage.getItem(process.env.REACT_APP_VARIABLE_C)]
        switch (choice){
            case 0:
                const res0 = await create(uc, content, 9)
                if(res0){
                    setErrors("Created")
                    clear("Created")
                    return null
                }else{
                    setErrors("Error creating")
                    return null
                }
                break;
            default: 
                return false
        }
    }
    const c = sessionStorage.getItem("key")
    return(
        <div className="new_order_container">
            <div className="new_order_form">
                    <div className="new_order_left">
                        <div className="new_order_uin">
                            <div className="new_order_label">
                                {data[0][0]} 
                            </div>
                            <input type="date" className="new_order_input" onChange={(e)=>{setDataa(e,[selection[0] , e.target.value])}} value={data[0][1]}/>
                        </div>     
                        <div className="new_order_uin">
                            <div className="new_order_label">
                                {data[1][0]} 
                            </div>
                            <select name="" id="" onChange={(e)=>{setDataa(e,[selection[1] , e.target.value])}} value={data[1][1]}>
                                <option value="0">1 Year or more</option>
                                <option value="1">6 months - 1 year</option>
                                <option value="2">1 Month</option>
                                <option value="3">3-4 Weeks</option>
                                <option value="4">1-2 Weeks</option>
                                <option value="5">Ugrent - Within 1 Week</option>
                            </select>
                        </div>   
                        <div className="new_order_uin">
                            <div className="new_order_label">
                                {data[2][0]} 
                            </div>
                            <input type="date" className="new_order_input" onChange={(e)=>{setDataa(e,[selection[2] , e.target.value])}} value={data[2][1]}/>
                        </div>   
                        <div className="new_order_uin">
                            <div className="new_order_label">
                                {data[3][0]} 
                            </div>
                            <input type="text" className="new_order_input" onChange={(e)=>{setDataa(e,[selection[3] , e.target.value])}} value={data[3][1]}/>
                        </div>   
                        <div className="new_order_uin">
                            <div className="new_order_label">
                                {data[4][0]} 
                            </div>
                            <input type="text" className="new_order_input" onChange={(e)=>{setDataa(e,[selection[4] , e.target.value])}} value={data[4][1]}/>
                        </div>   
                        <div className="new_order_uin">
                            <div className="new_order_label">
                                {data[5][0]} 
                            </div>
                            <input type="text" className="new_order_input" onChange={(e)=>{setDataa(e,[selection[5] , e.target.value])}} value={data[5][1]}/>
                        </div>   
                        <div className="new_order_uin">
                            <div className="new_order_label">
                                {data[6][0]} 
                            </div>
                            <input type="text" className="new_order_input" onChange={(e)=>{setDataa(e,[selection[6] , e.target.value])}} value={data[6][1]}/>
                        </div>   
                        <div className="new_order_uin">
                            <div className="new_order_label">
                                {data[7][0]} 
                            </div>
                            <input type="text" className="new_order_input" onChange={(e)=>{setDataa(e,[selection[7] , e.target.value])}} value={data[7][1]}/>
                        </div>   
                        <div className="new_order_uin">
                            <div className="new_order_label">
                                {data[8][0]} 
                            </div>
                            <input type="text" className="new_order_input" onChange={(e)=>{setDataa(e,[selection[8] , e.target.value])}} value={data[8][1]}/>
                        </div>   
                    </div>
                    <div className="new_order_right">
                        <div className="new_order_uin">
                            <div className="new_order_label">
                                {data[9][0]} 
                            </div>
                            <input type="text" className="new_order_input" onChange={(e)=>{setDataa(e,[selection[9] , e.target.value])}} value={data[9][1]}/>
                        </div>   
                        <div className="new_order_uin">
                            <div className="new_order_label">
                                {data[10][0]} 
                            </div>
                            <input type="text" className="new_order_input" onChange={(e)=>{setDataa(e,[selection[10] , e.target.value])}} value={data[10][1]}/>
                        </div>  
                        <div className="new_order_uin">
                            <div className="new_order_label">
                                {data[11][0]} 
                            </div>
                            <input type="text" className="new_order_input" onChange={(e)=>{setDataa(e,[selection[11] , e.target.value])}} value={data[11][1]}/>
                        </div>  
                        <div className="new_order_uin">
                            <div className="new_order_label">
                                {data[12][0]} 
                            </div>
                            <input type="text" className="new_order_input" onChange={(e)=>{setDataa(e,[selection[12] , e.target.value])}} value={data[12][1]}/>
                        </div>  
                        <div className="new_order_uin">
                            <div className="new_order_label">
                                {data[13][0]} 
                            </div>
                            <input type="text" className="new_order_input" onChange={(e)=>{setDataa(e,[selection[13] , e.target.value])}} value={data[13][1]}/>
                        </div>  
                        <div className="new_order_uin">
                            <div className="new_order_label">
                                {data[14][0]} 
                            </div>
                            <input type="text" className="new_order_input" onChange={(e)=>{setDataa(e,[selection[14] , e.target.value])}} value={data[14][1]}/>
                        </div>  
                        <div className="new_order_uin">
                            <div className="new_order_label">
                                {data[15][0]} 
                            </div>
                            <input type="text" className="new_order_input" onChange={(e)=>{setDataa(e,[selection[15] , e.target.value])}} value={data[15][1]}/>
                        </div>  
                        <div className="new_order_uin">
                            <div className="new_order_label">
                                {data[16][0]} 
                            </div>
                            <input type="text" className="new_order_input" onChange={(e)=>{setDataa(e,[selection[16] , e.target.value])}} value={data[16][1]}/>
                        </div>  
                        <div className="new_order_uin">
                            <div className="new_order_label">
                                {data[17][0]} 
                            </div>
                            <input type="text" className="new_order_input" onChange={(e)=>{setDataa(e,[selection[17] , e.target.value])}} value={data[17][1]}/>
                        </div>  
                        <div className="new_order_uin">
                            <div className="new_order_label">
                                {data[18][0]} 
                            </div>
                            <input type="text" className="new_order_input" onChange={(e)=>{setDataa(e,[selection[18] , e.target.value])}} value={data[18][1]}/>
                        </div>  
                        <div className="new_order_uin">
                            <div className="new_order_label">
                                {data[19][0]} 
                            </div>
                            <select name="" id="" onChange={(e)=>{setDataa(e,[selection[19] , e.target.value])}} value={data[19][1]}>
                                <option value="0">Cancelled</option>
                                <option value="1">New</option>
                                <option value="2">25%</option>
                                <option value="3">50%</option>
                                <option value="4">75%</option>
                                <option value="5">Completed</option>
                            </select>
                        </div>  
                    </div>
                    <div>
                        {
                            c?
                            <div>
                                <button className="button-7" onClick={(e)=>{processData(e,data, 0)}}>Create</button>   
                                <button className="button-7" onClick={(e)=>{clear("")}}>Clear</button>  
                            </div>
                            : <div></div>
                        }
                    </div>
                    <div>
                        {errors}
                    </div>
            </div>
        </div>
    )
}
export default New_order