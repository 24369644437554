/*
Delete
*//*
Create
*/
import axios from 'axios'
const link = process.env.REACT_APP_S    
const dLinks = [process.env.REACT_APP_VARIABLE_DL1,process.env.REACT_APP_VARIABLE_DL2,process.env.REACT_APP_VARIABLE_DL3,process.env.REACT_APP_VARIABLE_DL4,process.env.REACT_APP_VARIABLE_DL5,process.env.REACT_APP_VARIABLE_DL6,process.env.REACT_APP_VARIABLE_DL7,process.env.REACT_APP_VARIABLE_DL8,process.env.REACT_APP_VARIABLE_DL9,"/recruitment/delete","/employee/delete","/contact/delete","/lead/delete"]
    

const deleteC = async(uc, content, request) =>{
    
    try{
        
        
        const res = await axios.put(`${link}${dLinks[request]}`,
        {
            k : process.env.REACT_APP_K,
            content,
            'kk': uc[0],
            'kk1': uc[1]
        }) 
        if(res.data.request === true){
            return true
        }else{
            return false
        }
    }catch{
        return false
    }
}
export default deleteC