import React, {useState, useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import edit from "../sf/req/dt.js"
import create from "../sf/req/ce.js"
import './account.css'
import {valid3, valid4} from '../sf/re.js'
const link = process.env.REACT_APP_S
const Account_menu = () =>{
    let navigate = useNavigate();
    const home = <button className="button-66" onClick={(e)=>{e.preventDefault();navigate('/Menu')}}>Home</button>
    const [data, setData] = useState([["Company's Legal Name",""],["Company Address",""],["Company Phone",""],["Company E-mail",""],["First Name", ""], ["Last Name", ""], ["Job Title", ""], ["Phone", ""], ["E-mail", ""], ["Password", ""]])
    const [errors,setErrors] = useState("")
    const selection = ["Company's Legal Name","Company Address","Company Phone","Company E-mail","First Name","Last Name","Job Title","Phone","E-mail", "Password"]
   useEffect(()=>{
        getData("", 1, 0)
   },[])
   const getData = async (content, choice, request) =>{
    try{
        const getOneLinks = ["/accounts/by_id"]
        const uc = [sessionStorage.getItem(process.env.REACT_APP_VARIABLE_B),sessionStorage.getItem(process.env.REACT_APP_VARIABLE_C)]
        switch(choice){
            case 1: 
                const res2 = await axios.post(`${link}${getOneLinks[request]}`,
                {
                    k : process.env.REACT_APP_K,
                    content,
                    'kk': uc[0],
                    'kk1': uc[1]
                })  
                if(!res2.data.request){
                    setErrors("Please Login or Create an Account!")
                    return null
                }
                setData([["Company's Legal Name", res2.data.item.User.Company_name],["Company Address",res2.data.item.User.Company_address],["Company Phone",res2.data.item.User.Company_phone],["Company E-mail",res2.data.item.User.Company_email],["First Name", res2.data.item.User.FirstN], ["Last Name", res2.data.item.User.LastN], ["Job Title", res2.data.item.User.Job_title], ["Phone", res2.data.item.User.Phone], ["E-mail", res2.data.item.User.EmailAddress],["Password", ""]])
                return null
            default:
                return [false, false]  
        }
    }catch{
        return [false, "Something went wrong"]
    } 
}
const sendData = async(e, content, choice) =>{
    e.preventDefault()
    if(!valid3.test(data[3][1]) && choice !== 1 || 2){
        setErrors("E-mail is not valid!")
        return null
    }
    if(!valid4.test(data[2][1])){
        setErrors("Phone number is not valid!")
        return null
    }
    const uc = [sessionStorage.getItem(process.env.REACT_APP_VARIABLE_B),sessionStorage.getItem(process.env.REACT_APP_VARIABLE_C)]
    switch (choice){
        case 0:
            const res2 = await edit(uc, content, 14)
            if(res2){
                setErrors("Updated")
                getData("", 1,0)
                return null
            }else{
                setErrors("Error updating")
                return null
            }
            break;
        case 1:
            const res1 = await create(uc, "requesting all data", 10)
            if(res1){
                setErrors("Request For All Data Sent")
                getData("", 1,0)
                return null
            }else{
                setErrors("Error Sending Request, Please try again later.")
                return null
            }
            break;
        case 2:
            const res3 = await create(uc, "reset my password", 11)
            if(res3){
                setErrors("Password Reset Sent")
                getData("", 1,0)
                return null
            }else{
                setErrors("Error Sending Request, Please try again later.")
                return null
            }
            break;
        default: 
            return false
    }
}
        const setDataa = (e,t) =>{
            e.preventDefault()
            if(t[0].length > 25 || t[1].length > 25){
                setErrors(`${t[0]} cannot be greater then 25 Characters.`)
                return null
            }
            const copy = [...data]
            switch(t[0]){
                case selection[0]:
                    copy[0] = t
                break;
                case selection[1]:
                    copy[1] = t
                break;
                case selection[2]:
                    copy[2] = t
                break;
                case selection[3]:
                    copy[3] = t
                break;
                case selection[4]:
                    copy[4] = t
                break;
                case selection[5]:
                    copy[5] = t
                break;
                case selection[6]:
                    copy[6] = t
                break;
                case selection[7]:
                    copy[7] = t
                break;
                case selection[8]:
                    copy[8] = t
                break;
                case selection[9]:
                    copy[9] = t
                break;
                default:
                    return false;
            }
            setData([...copy])
        }
    const c = sessionStorage.getItem("key")
    return(
        <div className="whole_container">
            {home}
            <div className="account_form">
                <center><h2>Modify Your Account</h2></center>
                    <div className="account_uin">
                        <div className="account_label">
                            {data[0][0]} {data[0][1]}
                        </div>
                        <input type="text" className="account_input" onChange={(e)=>{setDataa(e,[selection[0] , e.target.value])}} value={data[0][1]}/>
                    </div>     
                    <div className="account_uin">
                        <div className="account_label">
                            {data[1][0]} {data[1][1]}
                        </div>
                        <input type="text" className="account_input" onChange={(e)=>{setDataa(e,[selection[1] , e.target.value])}} value={data[1][1]}/>
                    </div>   
                    <div className="account_uin">
                        <div className="account_label">
                            {data[2][0]} {data[2][1]}
                        </div>
                        <input type="text" className="account_input" onChange={(e)=>{setDataa(e,[selection[2] , e.target.value])}} value={data[2][1]}/>
                    </div>   
                    <div className="account_uin">
                        <div className="account_label">
                            {data[3][0]} {data[3][1]}
                        </div>
                        <input type="text" className="account_input" onChange={(e)=>{setDataa(e,[selection[3] , e.target.value])}} value={data[3][1]}/>
                    </div>   
                    <div className="account_uin">
                        <div className="account_label">
                            {data[4][0]} {data[4][1]}
                        </div>
                        <input type="text" className="account_input" onChange={(e)=>{setDataa(e,[selection[4] , e.target.value])}} value={data[4][1]}/>
                    </div>   
                    <div className="account_uin">
                        <div className="account_label">
                            {data[5][0]} {data[5][1]}
                        </div>
                        <input type="text" className="account_input" onChange={(e)=>{setDataa(e,[selection[5] , e.target.value])}} value={data[5][1]}/>
                    </div>   
                    <div className="account_uin">
                        <div className="account_label">
                            {data[6][0]} {data[6][1]}
                        </div>
                        <input type="text" className="account_input" onChange={(e)=>{setDataa(e,[selection[6] , e.target.value])}} value={data[6][1]}/>
                    </div>   
                    <div className="account_uin">
                        <div className="account_label">
                            {data[7][0]} {data[7][1]}
                        </div>
                        <input type="text" className="account_input" onChange={(e)=>{setDataa(e,[selection[7] , e.target.value])}} value={data[7][1]}/>
                    </div>   
                    <div className="account_uin">
                        <div className="account_label">
                            {data[8][0]} {data[8][1]}
                        </div>
                        <input type="text" className="account_input" onChange={(e)=>{setDataa(e,[selection[8] , e.target.value])}} value={data[8][1]}/>
                    </div>  
                    <div className="account_uin">
                        <div className="account_label">
                            {data[9][0]} {data[9][1]} *
                        </div>
                        <input type="password" className="account_input" onChange={(e)=>{setDataa(e,[selection[9] , e.target.value])}} value={data[9][1]}/>
                    </div>  
                    {
                        c?
                        <div>
                            <button className="button-7" onClick={(e)=>{sendData(e, data, 0)}}>Update</button> 
                            <button className="button-7" onClick={(e)=>{sendData(e, "", 1)}}>Request All Data</button> 
                            <button className="button-7" onClick={(e)=>{sendData(e, "", 2)}}>Request Password Reset</button>
                        </div>
                        : <div></div>
                    }
                    {errors}
                </div>
            </div>
    )
}
export default Account_menu