import {Link } from "react-router-dom";
import Nav from '../native/nav_bar.jsx'
const Updates = () =>{
    return(
        <div>
            <Nav/>
            <div className="description_container">
               <center><h1>Changelogs</h1></center>
               <p>January 2024 2.0 - Security & Business Updates</p>
               <p>July 2023 1.9 - Navigation Bar location and styling</p>
               <p>July 2023 1.6 - Customer Relations : Manage contacts</p>
               <p>July 2023 1.6 - Customer Relations : Manage leads</p>
               <p>July 2023 1.5 - Human resources: Manage recruits</p>
               <p>July 2023 1.5 - Human resources: Manage users</p>
               <p>July 2023 1.5 - Customer relations: Manage leads</p>
               <p>July 2023 1.1 - Customer relations: Manage contacts</p>
               <p>July 2023 1.1 - Budget: Create budget</p>
             <center><h1>Future Updates</h1></center>
             <p>Projected August 2023 : Human Resources : Create and manage ethereum payroll accounts </p>
             <p>Projected August 2023 : Mobile application</p>
             </div>
            <center>
                <Link className="button-32" to={'/Report-bugs-form'}>Report Bugs</Link>
                <Link className="button-32" to={'/Feedback-form'}>Submit Feedback</Link>
            </center>
        </div>
    )
}
export default Updates