function calculateDaysBetweenDates(date1, date2) {
    // Convert the input strings to Date objects
    const startDate = new Date(date1);
    const endDate = new Date(date2);
    
    // Calculate the time difference in milliseconds
    const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());
    
    // Convert the time difference to days
    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
    
    return daysDifference;
    }

export default calculateDaysBetweenDates