import React from 'react'

const SupplyIn = () =>{

    //const [data, setData] = useState([])

    /*
        Update
        get all
        
    */
    return(
        <div className="">
            <div className="supplyInForm">
                <div className="uin">
                    <div className="supply_label">
                        <label htmlFor="">Supply In</label>
                    </div>
                    <div className="supply_input">
                        <input type="text" />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SupplyIn