import React, {useState} from 'react'
import axios from 'axios'
import Edit_order from './edit_order.js'
const link = process.env.REACT_APP_S
const One_order = () =>{
    const [data, setData] = useState("")
    const [retrieve, setRetrieved] = useState([])
    const [error, setError] = useState("")
    const [choice, setChoice] = useState(1)
    const getOrder = async(e, info, c) =>{
        e.preventDefault()
       if(info === "" || null || undefined){
        setError("Search cannot be empty")
        return null
       }
       var l = ""
       switch(c){
        case "ph":
            l = process.env.REACT_APP_VARIABLE_PH
            break;
        case "em":
            l = process.env.REACT_APP_VARIABLE_EM
            break;
        case "or":
            l = process.env.REACT_APP_VARIABLE_OR
            break;
        default:
            return null
       }
        const uc = [sessionStorage.getItem(process.env.REACT_APP_VARIABLE_B),sessionStorage.getItem(process.env.REACT_APP_VARIABLE_C)]
        const res = await axios.post(`${link}${l}`,{
            content : info, 
            k : process.env.REACT_APP_K,
            'kk': uc[0],
            'kk1': uc[1]
        })
        if(!res.data.request){
            setError("Sorry, could not find order, Please check input and try again!")
            return null
        }
        setError("")
        setRetrieved(res.data.item)
    }
    const getSelect = (info) =>{
        switch(info){
            case "0":
                return "1 Year <"
            case "1":
                return "6 Months <"
            case "2":
                return "1 Month"
            case "3":
                return "3-4 Weeks"
            case "4":
                return "2-3 Weeks"
            case "5":
                return "Urgent - Within 1 Week"
            default:
                return null
        }
    }
    const getSelect2 = (info) =>{
        switch(info){
            case "0":
                return "Cancelled"
            case "1":
                return "New"
            case "2":
                return "25%"
            case "3":
                return "50%"
            case "4":
                return "75%"
            case "5":
                return "Completed"
            default:
                return null
        }
    }
    const c = sessionStorage.getItem("key")
    const [data2, setData2] = useState("")
    switch(choice){
        case 0:
        return(
            <div>
                <button className="button-37" onClick={()=>{setChoice(1)}}>Back</button>
                <Edit_order name={data2}/>
            </div>
        )
        case 1:
        return(
            <div className="">
                <div className="order_search">
                    <div className="order_search_label">
                        <label>Search (Phone, E-mail, Order ID)</label>
                    </div>
                    <input className="search_input" type="text" onChange={(e)=>{setData(e.target.value)}} value={data} />
                    <div>

                    {
                        c?
                        <div>
                            <button className="button-7 search_button" onClick={(e)=>{getOrder(e, data,"ph")}}>Phone</button>
                            <button className="button-7 search_button" onClick={(e)=>{getOrder(e, data,"em")}}>E-mail</button>
                            <button className="button-7 search_button" onClick={(e)=>{getOrder(e, data,"oe")}}>Order ID</button>
                        </div>
                        : <div></div>
                    }
                    </div>
                </div>
                <div className="order_found_list">
                    {
                        retrieve.map((item, id)=>{
                            return(
                                <div key={id}>
                                    <div className="item_span_order">{getSelect2(item.item.order_status)}</div>
                                    <div className="item_span_order">{item.item.entry_date} </div>
                                    <div className="item_span_order">{getSelect(item.item.priority)}</div>
                                    <div className="item_span_order">{item.item.dead_line}</div>
                                    <div className="item_span_order">{item.item.days_left}</div>
                                    <div className="item_span_order">{item.item.tracking_id}</div>
                                    <div className="item_span_order">{item.item.description}</div> 
                                    <button className="button-7" onClick={(e)=>{setChoice(0); setData2(item._id)}}>Edit</button>
                                </div>
                            )
                        })
                        //Loop over response
                        //Display edit button to edit
                    }
                    {error}
                </div>
            </div>
        )
        default:
            return(
                <div>Sorry, an error happened.</div>
            )
    }
}
export default One_order