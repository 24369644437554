import React, {useState, useEffect} from 'react'
import axios from 'axios'
import './recruitment.css'
import sortThis from "../sf/st.js"
import create from "../sf/req/ce.js"
import deleteC from "../sf/req/de.js"
import edit from "../sf/req/dt.js"
const link = process.env.REACT_APP_S
const Recruit = () => {
    const [data, setData] = useState(
                                [["First Name",""],
                                ["Last Name",""],
                                ["E-mail",""],
                                ["Phone",""],
                                ["Background Check",""],
                                ["Position",""],
                                ["Monday",["MondayTime1","2"],["MondayTime2","3"]],
                                ["Tuesday",["TuesdayTime1",""],["TuesdayTime2",""]],
                                ["Wednesday",["WednesdayTime1",""],["WednesdayTime2",""]],
                                ["Thursday",["ThursdayTime1",""],["ThursdayTime2",""]],
                                ["Friday",["FridayTime1",""],["FridayTime2",""]],
                                ["Saturday",["SaturdayTime1",""],["SaturdayTime2",""]],
                                ["Sunday",["SundayTime1",""],["SundayTime2",""]],
                                ["Interview Date",""],
                                ["Interview Time",""],
                                ["Interview Feedback",""]
                            ])
    const selection = ["First Name","Last Name","E-mail","Phone","Background Check","Position",["Monday",'MondayTime1',"MondayTime2"],["Tuesday",'TuesdayTime1',"TuesdayTime2"],["Wednesday",'WednesdayTime1',"WednesdayTime2"],["Thursday",'Time1',"Time2"],["Friday",'FridayTime1',"FridayTime2"],["Saturday",'SaturdayTime1',"SaturdayTime2"],["Sunday",'SundayTime1',"SundayTime2"],"Interview Date","Interview Time","Interview Feedback"]
    const [retrieve, setRetrieved] = useState([])
    const [errors, setErrors] = useState("")
    const clear = (e,request) =>{
        e.preventDefault()
        setErrors(request)
        setData([
                ["First Name",""],
                ["Last Name",""],
                ["E-mail",""],
                ["Phone",""],
                ["Background Check",""],
                ["Position",""],
                ["Monday",["MondayTime1","2"],["MondayTime2","3"]],
                ["Tuesday",["TuesdayTime1",""],["TuesdayTime2",""]],
                ["Wednesday",["WednesdayTime1",""],["WednesdayTime2",""]],
                ["Thursday",["ThursdayTime1",""],["ThursdayTime2",""]],
                ["Friday",["FridayTime1",""],["FridayTime2",""]],
                ["Saturday",["SaturdayTime1",""],["SaturdayTime2",""]],
                ["Sunday",["SundayTime1",""],["SundayTime2",""]],
                ["Interview Date",""],
                ["Interview Time",""],
                ["Interview Feedback",""]
                ])
    }
        const [flip, setFlip] = useState(0)
        const sortInfo = async(e, choice) =>{
          e.preventDefault()
          if(choice === 1){
            //Date Earned
            const copy = retrieve
            const sorted = await sortThis(copy, "date_earned", `3${flip}`)
            setRetrieved(sorted)
          }
          if(choice === 2){
            //Amount
            const copy = retrieve
            const sorted = await sortThis(copy, "amount", `1${flip}`)
            setRetrieved(sorted)
          }
          if(choice === 3){
            //Payment type
            const copy = retrieve
            const sorted = await sortThis(copy, "payment_type", `1${flip}`)
            setRetrieved(sorted)
          }
          if(choice === 4){
            //Notes
            const copy = retrieve
            const sorted = await sortThis(copy, "notes", `2${flip}`)
            setRetrieved(sorted)
          }
          if(flip === 0){
            setFlip(1)
          }
          if(flip === 1){
            setFlip(0)
          }
        }
    const setDataa = (e,t) =>{
        e.preventDefault()
        if(t[0].length > 25 || t[1].length > 500){
            setErrors(`${t[0]} cannot be greater then 25 Characters.`)
            return null
        }
        const copy = [...data]
        switch(t[0]){
            case selection[0]:
                copy[0] = t
            break;
            case selection[1]:
                copy[1] = t
            break;
            case selection[2]:
                copy[2] = t
            break;
            case selection[3]:
                copy[3] = t
            break;
            case selection[4]:
                copy[4] = t
            break;
            case selection[5]:
                copy[5] = t
            break;
            case selection[6][1]:
                copy[6][1] = t
            break;
            case selection[6][2]:
                copy[6][2] = t
            break;
            case selection[7][1]:
                copy[7][1] = t
            break;
            case selection[7][2]:
                copy[7][2] = t
            break;
            case selection[8][1]:
                copy[8][1] = t
            break;
            case selection[8][2]:
                copy[8][2] = t
            break;
            case selection[9][1]:
                copy[9][1] = t
            break;
            case selection[9][2]:
                copy[9][2] = t
            break;
            case selection[10][1]:
                copy[10][1] = t
            break;
            case selection[10][2]:
                copy[10][2] = t
            break;
            case selection[11][1]:
                copy[11][1] = t
            break;
            case selection[11][2]:
                copy[11][2] = t
            break;
            case selection[12][1]:
                copy[12][1] = t
            break;
            case selection[12][2]:
                copy[12][2] = t
            break;
            case selection[13]:
                copy[13] = t
            break;
            case selection[14]:
                copy[14] = t
            break;
            case selection[15]:
                copy[15] = t
            break;
            default:
                return false;
        }
        setData([...copy])
    }
    useEffect(()=>{

        getData("", 0, 0)

    }, [])
    const getData = async (content, choice, request) =>{
        try{
            const getAllLinks = ["/recruitment/all"]
            const getOneLinks = ["/recruitment/one"]
            const uc = [sessionStorage.getItem(process.env.REACT_APP_VARIABLE_B),sessionStorage.getItem(process.env.REACT_APP_VARIABLE_C)]
            switch(choice){
                case 0:
                    const res = await axios.post(`${link}${getAllLinks[request]}`,
                    {
                        k : process.env.REACT_APP_K,
                        'kk': uc[0],
                        'kk1': uc[1]
                    })       
                    if(!res.data.request){
                        setErrors("")
                        return false
                    }
                    setRetrieved(res.data.item)
                    return null
                case 1: 
                    const res2 = await axios.post(`${link}${getOneLinks[request]}`,
                    {
                        k : process.env.REACT_APP_K,
                        content,
                        'kk': uc[0],
                        'kk1': uc[1]
                    })     
                    if(!res2.data.request){
                        setErrors("Error retrieving item")
                        return false
                    }
                    setData([
                        ["First Name", res2.data.item.item.FirstN],
                        ["Last Name",res2.data.item.item.LastN],
                        ["E-mail",res2.data.item.item.Email],
                        ["Phone",res2.data.item.item.Phone],
                        ["Background Check",res2.data.item.item.Background],
                        ["Position",res2.data.item.item.Position],
                        ["Monday",["MondayTime1",res2.data.item.item.Monday[1][1]],["MondayTime2",res2.data.item.item.Monday[2][1]]],
                        ["Tuesday",["TuesdayTime1",res2.data.item.item.Tuesday[1][1]],["TuesdayTime2",res2.data.item.item.Tuesday[2][1]]],
                        ["Wednesday",["WednesdayTime1",res2.data.item.item.Wednesday[1][1]],["WednesdayTime2",res2.data.item.item.Wednesday[2][1]]],
                        ["Thursday",["ThursdayTime1",res2.data.item.item.Thursday[1][1]],["ThursdayTime2",res2.data.item.item.Thursday[2][1]]],
                        ["Friday",["FridayTime1",res2.data.item.item.Friday[1][1]],["FridayTime2",res2.data.item.item.Friday[2][1]]],
                        ["Saturday",["SaturdayTime1",res2.data.item.item.Saturday[1][1]],["SaturdayTime2",res2.data.item.item.Saturday[2][1]]],
                        ["Sunday",["SundayTime1",res2.data.item.item.Sunday[1][1]],["SundayTime2",res2.data.item.item.Sunday[2][1]]],
                        ["Interview Date",res2.data.item.item.InterviewD],
                        ["Interview Time",res2.data.item.item.InterviewT],
                        ["Interview Feedback",res2.data.item.item.InterviewF],
                        ["_id", res2.data.item._id]])
                    return null
                default:
                    return false 
            }
        }catch{
            return [false, "Something went wrong"]
        } 
    }
    const processData = async(e, content, choice) =>{
        e.preventDefault()
        const uc = [sessionStorage.getItem(process.env.REACT_APP_VARIABLE_B),sessionStorage.getItem(process.env.REACT_APP_VARIABLE_C)]
        switch (choice){
            case 0:
                const res0 = await create(uc, content, 12)
                if(res0){
                    setErrors("Created")
                    clear(e,"Created")
                    getData(null, 0,0)
                    return null
                }else{
                    setErrors("Error creating")
                    return null
                }
                break;
            case 1:
                const res1 = await deleteC(uc, content, 9)
                if(res1){
                    setErrors("Removed")
                    clear(e,"Removed")
                    getData(null, 0, 0)
                    return null
                }else{
                    setErrors("Error removing")
                }
                break;
            case 2:
                const res2 = await edit(uc, content, 16)
                if(res2){
                    setErrors("Updated")
                    clear(e,"Updated")
                    getData(null, 0,0)
                    return null
                }else{
                    setErrors("Error updating")
                }
                break;
            default: 
                return false
        }
    }
    const c = sessionStorage.getItem("key")
    return(
        <div className="whole_container">
        <div className="recruit_container">
                <div className="recruit_list">

                <div className="item_container">
                        <div className="item_span" onClick={(e)=>{sortInfo(e,1)}}>Last Name</div>
                        <div className="item_span" onClick={(e)=>{sortInfo(e,2)}}>Interview</div>
                        <div className="item_span" onClick={(e)=>{sortInfo(e,3)}}>E-mail</div>
                        <div className="item_span" onClick={(e)=>{sortInfo(e,4)}}>Phone</div>
                </div>
                {
                    retrieve.map((item, id)=>{
                        return (
                            <div key={id} className="item_container">
                                <div className="item_span">{item.item.LastN} </div>
                                <div className="item_span">{item.item.InterviewD} {item.item.InterviewT}</div>
                                <div className="item_span">{item.item.Email}</div>
                                <div className="item_span">{item.item.Phone}</div>
                                <button className="button-2" onClick={(e)=>{getData(item._id, 1, 0)}}>Edit</button>
                                <button className="button-2" onClick={(e)=>{processData(e, item._id, 1)}}>Remove</button>
                            </div>
                        )
                    })
                }
                </div>
                <form action="" className="recruit">
                    <div className="recruit_contact">
                        <div className="recruit_uin">
                            <label htmlFor="" className="recruit_label">First Name</label>
                            <input type="text"  className="recruit_input" onChange={(e)=>{setDataa(e,[selection[0] , e.target.value])}} value={data[0][1]}/>
                        </div>
                        <div className="recruit_uin">
                            <label htmlFor="" className="recruit_label">Last Name</label>
                            <input type="text"  className="recruit_input" onChange={(e)=>{setDataa(e,[selection[1] , e.target.value])}} value={data[1][1]}/>
                        </div>
                        <div className="recruit_uin">
                            <label htmlFor="" className="recruit_label">E-mail</label>
                            <input type="text"  className="recruit_input" onChange={(e)=>{setDataa(e,[selection[2] , e.target.value])}} value={data[2][1]}/>
                        </div>
                        <div className="recruit_uin">
                            <label htmlFor="" className="recruit_label">Phone</label>
                            <input type="text"  className="recruit_input" onChange={(e)=>{setDataa(e,[selection[3] , e.target.value])}} value={data[3][1]}/>
                        </div>
                        <div className="recruit_uin">
                            <label htmlFor="" className="recruit_label">Background Check</label>
                            <select name="" id="" onChange={(e)=>{setDataa(e,[selection[4] , e.target.value])}} value={data[4][1]}>
                                <option value="0">Pending</option>
                                <option value="1">No</option>
                                <option value="2">Yes</option>
                            </select>
                        </div>
                        <div className="recruit_uin">
                            <label htmlFor="" className="recruit_label">Position </label>
                            <input type="text"  className="recruit_input" onChange={(e)=>{setDataa(e,[selection[5] , e.target.value])}} value={data[5][1]}/>
                        </div>
                    </div>
                    <div className="time_available">
                        <div className="recruit_uin">
                            <label htmlFor="" className="recruit_label">Monday  </label>
                            <input type="time" name="" id="" onChange={(e)=>{setDataa(e,[selection[6][1] , e.target.value])}} value={data[6][1][1]}/>
                            <input type="time" name="" id="" onChange={(e)=>{setDataa(e,[selection[6][2] , e.target.value])}} value={data[6][2][1]}/>
                        </div>
                        <div className="recruit_uin">
                            <label htmlFor="" className="recruit_label">Tuesday  </label>
                            <input type="time" name="" id="" onChange={(e)=>{setDataa(e,[selection[7][1] , e.target.value])}} value={data[7][1][1]}/>
                            <input type="time" name="" id="" onChange={(e)=>{setDataa(e,[selection[7][2] , e.target.value])}} value={data[7][2][1]}/>
                        </div>
                        <div className="recruit_uin">
                            <label htmlFor="" className="recruit_label">Wednesday </label>
                            <input type="time" name="" id="" onChange={(e)=>{setDataa(e,[selection[8][1] , e.target.value])}} value={data[8][1][1]}/>
                            <input type="time" name="" id="" onChange={(e)=>{setDataa(e,[selection[8][2] , e.target.value])}} value={data[8][2][1]}/>
                        </div>
                        <div className="recruit_uin">
                            <label htmlFor="" className="recruit_label">Thursday </label>
                            <input type="time" name="" id="" onChange={(e)=>{setDataa(e,[selection[9][1] , e.target.value])}} value={data[9][1][1]}/>
                            <input type="time" name="" id="" onChange={(e)=>{setDataa(e,[selection[9][2] , e.target.value])}} value={data[9][2][1]}/>
                        </div>
                        <div className="recruit_uin">
                            <label htmlFor="" className="recruit_label">Friday </label>
                            <input type="time" name="" id="" onChange={(e)=>{setDataa(e,[selection[10][1] , e.target.value])}} value={data[10][1][1]}/>
                            <input type="time" name="" id="" onChange={(e)=>{setDataa(e,[selection[10][2] , e.target.value])}} value={data[10][2][1]}/>
                        </div>
                        <div className="recruit_uin">
                            <label htmlFor="" className="recruit_label">Saturday </label>
                            <input type="time" name="" id="" onChange={(e)=>{setDataa(e,[selection[11][1] , e.target.value])}} value={data[11][1][1]}/>
                            <input type="time" name="" id="" onChange={(e)=>{setDataa(e,[selection[11][2] , e.target.value])}} value={data[11][2][1]}/>
                        </div>
                        <div className="recruit_uin">
                            <label htmlFor="" className="recruit_label">Sunday </label>
                            <input type="time" name="" id="" onChange={(e)=>{setDataa(e,[selection[12][1] , e.target.value])}} value={data[12][1][1]}/>
                            <input type="time" name="" id="" onChange={(e)=>{setDataa(e,[selection[12][2] , e.target.value])}} value={data[12][2][1]}/>
                        </div>
                    </div>
                        <div className="recruit_uin">
                            <label htmlFor="" className="recruit_label">Interview Date & Time</label>
                            <input type="date"  className="recruit_input recruit_date" onChange={(e)=>{setDataa(e,[selection[13] , e.target.value])}} value={data[13][1]}/>
                            <input type="time" className="recruit_time" name="" id=""   onChange={(e)=>{setDataa(e,[selection[14] , e.target.value])}} value={data[14][1]}/>
                        </div>
                    <div className="questions_asked">
                        <div className="recruit_uin">
                            <label htmlFor="" className="recruit_label">Interview Feedback</label>
                            <textarea name="" id="" cols="40" rows="5" onChange={(e)=>{setDataa(e,[selection[15] , e.target.value])}} value={data[15][1]}></textarea>
                        </div>
                    </div>
                    {
                        c?
                        <div>
                            <button className="button-7" onClick={(e)=>{processData(e,data,2)}}>Update</button>
                            <button className="button-7" onClick={(e)=>{clear(e,"")}}>Clear</button>
                            <button className="button-7" onClick={(e)=>{processData(e,data,0)}}>Create</button>
                        </div>
                        : <div></div>
                    }
                    <div className="error">
                        {errors}
                    </div>
                </form>
            </div>

        </div>
    )
}
export default Recruit