import Nav from '../native/nav_bar.jsx'
const Instructions = () =>{
    return(
        <div>
            <Nav/>
            <div className="description_container">
               <center><h1>How to:</h1></center>
                <h2>How to create an account</h2> 
                <p>At the login screen, choose a username and password to use. Enter that username and password and upon success you will be notified.</p> 
                <h2>Delete an account</h2>
                <p>Login and go to the "Account" component. There you can submit a request for your account to be deleted or to retrieve everything related to you account.</p>
             </div>
        </div>
    )
}
export default Instructions