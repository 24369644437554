import React from 'react'

const SupplyOut= () =>{

    //const [data, setData] = useState([])

    /*
        Update
        get all
    */
    return(
        <div className="whole_container">
            <div className="supplyOutForm">
                <div className="uin">
                        <div className="supply_label">
                            <label htmlFor="">Supply Out</label>
                        </div>
                        <div className="supply_input">
                            <input type="text" />
                        </div>
                    </div>
                </div>
        </div>
    )
}
export default SupplyOut