import React, {useState, useEffect} from 'react'
import axios from 'axios'
import './devices.css'
import create from "../sf/req/ce.js"
import deleteC from "../sf/req/de.js"
import edit from "../sf/req/dt.js"
import sortThis from "../sf/st.js"
const link = process.env.REACT_APP_S
const Devices = () =>{
    const [data, setData] = useState([["Name",""],["IMEI",""],["Type",""],["Condition",""],["Year",""],["Serial Number",""],["Brand",""],["Model",""],["Location",""],["Date Acquired",""]])
    const selection = ["Name","Imei","Type","Condition","Year", "Serial Number","Brand","Model","Location", "Date Acquired"]
    const [retrieve, setRetrieved] = useState([])  
    const [retrieve1, setRetrieved1] = useState([])    
    const ads = process.env.REACT_APP_BUILD
    const [errors, setErrors] = useState("")
        const [flip, setFlip] = useState(0)
        const sortInfo = async(e, choice) =>{
          e.preventDefault()
          if(choice === 1){
            //Purchase Date
            const copy = retrieve
            const sorted = await sortThis(copy, "date", `3${flip}`)
            setRetrieved(sorted)
          }
          if(choice === 2){
            //Purchase Amount
            const copy = retrieve
            const sorted = await sortThis(copy, "payment_amount", `1${flip}`)
            setRetrieved(sorted)
          }
          if(choice === 3){
            //Payment Type
            const copy = retrieve
            const sorted = await sortThis(copy, "payment_type", `1${flip}`)
            setRetrieved(sorted)
          }
          if(choice === 4){
            //Items
            const copy = retrieve
            const sorted = await sortThis(copy, "items", `2${flip}`)
            setRetrieved(sorted)
          }
          if(flip === 0){
            setFlip(1)
          }
          if(flip === 1){
            setFlip(0)
          }
          
        }
    const setDataa = (e,t) =>{
        e.preventDefault()
        if(t[0].length > 25 || t[1].length > 25){
            setErrors(`${t[0]} cannot be greater then 25 Characters.`)
            return null
        }
        const copy = [...data]
        switch(t[0]){
            case selection[0]:
                copy[0] = t
            break;
            case selection[1]:
                copy[1] = t
            break;
            case selection[2]:
                copy[2] = t
            break;
            case selection[3]:
                copy[3] = t
            break;
            case selection[4]:
                copy[4] = t
            break;
            case selection[5]:
                copy[5] = t
            break;
            case selection[6]:
                copy[6] = t
            break;
            case selection[7]:
                copy[7] = t
            break;
            case selection[8]:
                copy[8] = t
            break;
            case selection[9]:
                copy[9] = t
            break;
            default:
                return false;
        }
        setData([...copy])
    }
    const clear = (request) =>{
        setErrors(request)
        setData([["Name",""],["IMEI",""],["Type",""],["Condition",""],["Year",""],["Serial Number",""],["Brand",""],["Model",""],["Location",""],["Date Acquired",""]])
    }
    useEffect(() => {
        getData("", 0,0)
        getData("", 2,1)
    }, [])

    const getData = async (content, choice, request) => {
        try{
            const getAllLinks = [process.env.REACT_APP_VARIABLE_GL1,process.env.REACT_APP_VARIABLE_GL2,process.env.REACT_APP_VARIABLE_GL3,process.env.REACT_APP_VARIABLE_GL4,process.env.REACT_APP_VARIABLE_GL5,process.env.REACT_APP_VARIABLE_GL6,process.env.REACT_APP_VARIABLE_GL7,process.env.REACT_APP_VARIABLE_GL8,process.env.REACT_APP_VARIABLE_GL9,process.env.REACT_APP_VARIABLE_GL10,process.env.REACT_APP_VARIABLE_GL11,process.env.REACT_APP_VARIABLE_GL12,process.env.REACT_APP_VARIABLE_GL13,process.env.REACT_APP_VARIABLE_GL14]
            const getOneLinks = [process.env.REACT_APP_VARIABLE_GO1,process.env.REACT_APP_VARIABLE_GO2,process.env.REACT_APP_VARIABLE_GO3,process.env.REACT_APP_VARIABLE_GO4,process.env.REACT_APP_VARIABLE_GO5,process.env.REACT_APP_VARIABLE_GO6,process.env.REACT_APP_VARIABLE_GO7,process.env.REACT_APP_VARIABLE_GO8,process.env.REACT_APP_VARIABLE_GO9,process.env.REACT_APP_VARIABLE_GO10,process.env.REACT_APP_VARIABLE_GO11,process.env.REACT_APP_VARIABLE_GO12,process.env.REACT_APP_VARIABLE_GO13,process.env.REACT_APP_VARIABLE_GO14]
            const uc = [sessionStorage.getItem(process.env.REACT_APP_VARIABLE_B),sessionStorage.getItem(process.env.REACT_APP_VARIABLE_C)]
            switch(choice){
                case 0:
                    const res = await axios.post(`${link}${getAllLinks[request]}`,
                    {
                        k : process.env.REACT_APP_K,
                        'kk': uc[0],
                        'kk1': uc[1]
                    })  
                    if(!res.data.request){
                        setErrors("Please Login or Create an Account!")
                        return null
                    }
                    setRetrieved(res.data.item)
                    return null
                case 1: 
                    const res2 = await axios.post(`${link}${getOneLinks[request]}`,
                    {
                        k : process.env.REACT_APP_K,
                        content,
                        'kk': uc[0],
                        'kk1': uc[1]
                    })  
                    if(!res2.data.request){
                        setErrors("Please Login or Create an Account!")
                        return null
                    }
                    setData([["Name", res2.data.item.item.device_name],["IMEI",res2.data.item.item.device_imei],["Type",res2.data.item.item.device_type],["Condition",res2.data.item.item.device_condition],["Year",res2.data.item.item.device_year],["Serial Number",res2.data.item.item.serial_number],["Brand",res2.data.item.item.device_brand],["Model",res2.data.item.item.device_model],["Location",res2.data.item.item.device_location],["Date Acquired",res2.data.item.item.date_acquired],["_id", res2.data.item._id]])
                    return null
                case 2:
                        const res3 = await axios.post(`${link}${getAllLinks[request]}`,
                        {
                            k : process.env.REACT_APP_K,
                            'kk': uc[0],
                            'kk1': uc[1]
                        })  
                        if(!res3.data.request){
                            setErrors("")
                            return null
                        }
                        setRetrieved1(res3.data.item)
                        return null
                default:
                    return [false, false]  
            }
        }catch{
            return [false, "Something went wrong"]
        } 
    }
    const processData = async(e, content, choice) =>{
        e.preventDefault()
        const uc = [sessionStorage.getItem(process.env.REACT_APP_VARIABLE_B),sessionStorage.getItem(process.env.REACT_APP_VARIABLE_C)]
        switch (choice){
            case 0:
                const res0 = await create(uc, content, 0)
                if(res0){
                    setErrors("Created")
                    clear("Created")
                    getData("", 0, 0)
                    return null
                }else{
                    setErrors("Error creating")
                    return null
                }
                break;
            case 1:
                const res1 = await deleteC(uc, content, 0)
                if(res1){
                    setErrors("Removed")
                    clear("Removed")
                    getData("", 0, 0)
                    return null
                }else{
                    setErrors("Error removing")
                    return null
                }
                break;
            case 2:
                const res2 = await edit(uc, content, 0)
                if(res2){
                    setErrors("Updated")
                    clear("Updated")
                    getData("", 0, 0)
                    return null
                }else{
                    setErrors("Error updating")
                    return null
                }
                break;
            default: 
                return false
        }
    }
    const getSelect = (info) =>{
        switch(info){
            case "0":
                return "New"
            case "1":
                return "Used"
            case "2":
                return "Damaged"
            case "3":
                return "Needs Repairs"
            default:
                return null
        }
    }
    const c = sessionStorage.getItem("key")
    return(
        <div className="device_container">
            <div className="left_container">

                <div className="device_list">

                    <div className="item_container">
                            <div className="item_span" onClick={(e)=>{sortInfo(e,1)}}>Name</div>
                            <div className="item_span" onClick={(e)=>{sortInfo(e,2)}}>Type</div>
                            <div className="item_span" onClick={(e)=>{sortInfo(e,3)}}>Condition</div>
                            <div className="item_span" onClick={(e)=>{sortInfo(e,4)}}>Date Acquired</div>
                    </div>
                    <div className="device_item_container">
                        {
                            retrieve.map((item, id)=>{
                                return(
                                    <div key={id}>
                                    <div className="item_span">{item.item.device_name} </div>
                                    <div className="item_span">{item.item.device_type}</div>
                                    <div className="item_span">{getSelect(item.item.device_condition)}</div>
                                    <div className="item_span">{item.item.date_acquired}</div>
                                    <button className="button-2" onClick={(e)=>{getData(item._id, 1, 0)}}>Edit</button>
                                    <button className="button-2" onClick={(e)=>{processData(e, item._id, 1)}}>Remove</button>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="device_incoming">  
                <div className="item_container">
                            <div className="item_span" onClick={(e)=>{sortInfo(e,1)}}>Name</div>
                            <div className="item_span" onClick={(e)=>{sortInfo(e,2)}}>Type</div>
                            <div className="item_span" onClick={(e)=>{sortInfo(e,3)}}>Condition</div>
                            <div className="item_span" onClick={(e)=>{sortInfo(e,4)}}>Date Acquired</div>
                </div>
                    <div className="device_item_container">
                        {
                            retrieve1.map((item, id)=>{
                                return(
                                    <div key={id}>
                                    <div className="item_span">{item.item.device_name} </div>
                                    <div className="item_span">{item.item.device_type}</div>
                                    <div className="item_span">{item.item.device_condition}</div>
                                    <div className="item_span">{item.item.date_acquired}</div>
                                
                                    <button className="button-2" onClick={(e)=>{getData(item._id, 1, 0)}}>Accept</button>
                                    <button className="button-2" onClick={(e)=>{processData(e, item._id, 1)}}>Decline</button>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="right_container">
                <div className="device_form">
                    <div className="device_uin">
                        <div className="device_label">
                            {data[0][0]} 
                        </div>
                        <input type="text" className="device_input" onChange={(e)=>{setDataa(e,[selection[0] , e.target.value])}} value={data[0][1]}/>
                    </div>     
                    <div className="device_uin">
                        <div className="device_label">
                            {data[1][0]} 
                        </div>
                        <input type="text" className="device_input" onChange={(e)=>{setDataa(e,[selection[1] , e.target.value])}} value={data[1][1]}/>
                    </div>   
                    <div className="device_uin">
                        <div className="device_label">
                            {data[2][0]} 
                        </div>
                        <input type="text" className="device_input" onChange={(e)=>{setDataa(e,[selection[2] , e.target.value])}} value={data[2][1]}/>
                    </div>   
                    <div className="device_uin">
                        <div className="device_label">
                            {data[3][0]} 
                        </div>
                        <select name="" id="" onChange={(e)=>{setDataa(e,[selection[3] , e.target.value])}} value={data[3][1]}>
                            <option value="0">New</option>
                            <option value="1">Used</option>
                            <option value="2">Damaged</option>
                            <option value="3">Needs Repairs</option>
                        </select>
                    </div>   
                    <div className="device_uin">
                        <div className="device_label">
                            {data[4][0]} 
                        </div>
                        <input type="text" className="device_input" onChange={(e)=>{setDataa(e,[selection[4] , e.target.value])}} value={data[4][1]}/>
                    </div>   
                    <div className="device_uin">
                        <div className="device_label">
                            {data[5][0]} 
                        </div>
                        <input type="text" className="device_input" onChange={(e)=>{setDataa(e,[selection[5] , e.target.value])}} value={data[5][1]}/>
                    </div>   
                    <div className="device_uin">
                        <div className="device_label">
                            {data[6][0]} 
                        </div>
                        <input type="text" className="device_input" onChange={(e)=>{setDataa(e,[selection[6] , e.target.value])}} value={data[6][1]}/>
                    </div>   
                    <div className="device_uin">
                        <div className="device_label">
                            {data[7][0]} 
                        </div>
                        <input type="text" className="device_input" onChange={(e)=>{setDataa(e,[selection[7] , e.target.value])}} value={data[7][1]}/>
                    </div>   
                    <div className="device_uin">
                        <div className="device_label">
                            {data[8][0]} 
                        </div>
                        <input type="text" className="device_input" onChange={(e)=>{setDataa(e,[selection[8] , e.target.value])}} value={data[8][1]}/>
                    </div>   
                    <div className="device_uin">
                        <div className="device_label">
                            {data[9][0]} 
                        </div>
                        <input type="date" className="device_input" onChange={(e)=>{setDataa(e,[selection[9] , e.target.value])}} value={data[9][1]}/>
                    </div>   
                  {
                    c?
                    <div>
                        <button className="button-7" onClick={(e)=>{processData(e,data, 0)}}>Create</button>   
                        <button className="button-7" onClick={(e)=>{clear("")}}>Clear</button>  
                        <button className="button-7" onClick={(e)=>{processData(e,data, 2)}}>Update</button>     
                    </div>
                    : <div></div>
                  }
                    <div>
                        <div className="error">
                            {errors}
                        </div>
                    </div>
                </div>
                <div className="device_totals">
                </div>
            </div>
                    {
                        ads?
                        <div><script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3199046355212507"
                        crossorigin="anonymous"></script></div>
                        : <div></div>
                    }
        </div>
    )
}
export default Devices